import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import lodash from "lodash";
import $ from "jquery";
import * as moment from "moment";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import bankAccount from "../../media/img/payments/bank-account.png";
import creditCard from "../../media/img/payments/credit-card.png";
import charges from "../../util/charges";
import * as constants from "../../util/constants";
import { parseDateForPad } from "../../util/dates";
import Alert from "../common/Alert";
import FieldCharges from "../common/FieldCharges";
import FieldDate from "../common/FieldDate";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import Modal from "../common/Modal";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import SkeletonLoader from "./SkeletonLoader";
import ButtonSave from "./ButtonSave";
import CardBrandIcon from "./CardBrandIcon";
import CardPreview from "./CardPreview";
import FieldAddress from "./FieldAddress";
import FieldBirthDate from "./FieldBirthDate";
import FieldCardNumber from "./FieldCardNumber";
import FieldCheckbox from "./FieldCheckbox";
import FieldCountry from "./FieldCountry";
import FieldSwitch from "./FieldSwitch";
import ModalUnionPay from "./ModalUnionPay";
class OpenCharges extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            blockPayments: {
                blockBankPayment: false,
                blockCreditPayment: false
            },
            propertyLease: {},
            property: {},
            company: {},
            recurringSchedule: {},
            recurringScheduleLoading: false,
            nextScheduledPayment: {},
            recurringScheduleCustomAmt: {
                customAmtEnabled: false
            },

            paymentMethod: {},
            paymentMethodList: [],
            billingAddress: {},
            billingAddressList: [],

            showBillingAddressForm: false,
            populateBillingAddressForm: false,

            showCashPaymentMethod: false,
            showCreditCardPaymentMethod: false,
            showBankPaymentMethod: false,

            recurringStartDate: '',

            charge: {
                code: '',
                name: '',
                amount: '',
                balance: '',
                postMonth: '',
            },

            autopayCharges: [],

            openCharges: [],
            openChargesLoading: false,
            balanceCharges: [],
            customChargeCodes: [],

            creditCardSecurityCodeLabel: 'CVV',
            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            currentBalance: 0,

            openChargesOnly: true,
            integratedBalance: false,

            validationList: [],

            isRecurringPaymentMethodPresent: true,

            propertyCountry: '',
        };

        this.getOpenCharges = this.getOpenCharges.bind(this);

        this.getRecurringSchedule = this.getRecurringSchedule.bind(this);
        this.initRecurringSchedule = this.initRecurringSchedule.bind(this);
        this.saveRecurringSchedule = this.saveRecurringSchedule.bind(this);

        this.getCurrentBalance = this.getCurrentBalance.bind(this);

        this.initOpenCharge = this.initOpenCharge.bind(this);
        this.initOpenCredit = this.initOpenCredit.bind(this);
        this.selectOpenCharge = this.selectOpenCharge.bind(this);
        this.saveOpenCharge = this.saveOpenCharge.bind(this);
        this.deleteOpenCharge = this.deleteOpenCharge.bind(this);

        this.addAutopayCharge = this.addAutopayCharge.bind(this);
        this.removeAutopayCharge = this.removeAutopayCharge.bind(this);
        this.importCharges = this.importCharges.bind(this);
        this.importAutopayCharges = this.importAutopayCharges.bind(this);
        this.postRecurringCharges = this.postRecurringCharges.bind(this);

        this.initPaymentMethod = this.initPaymentMethod.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.searchBillingAddresses = this.searchBillingAddresses.bind(this);
        this.initBillingAddress = this.initBillingAddress.bind(this);

        this.handleChangeChargeCode = this.handleChangeChargeCode.bind(this);
        this.handleChangeRecurringScheduleStatus = this.handleChangeRecurringScheduleStatus.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePopulateBillingAddress = this.handleChangePopulateBillingAddress.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);

        this.searchAcceptedPaymentTypes = this.searchAcceptedPaymentTypes.bind(this);
        this.getPropertyDetails = this.getPropertyDetails.bind(this);
        this.fetchAdditionalInfo = this.fetchAdditionalInfo.bind(this);
        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);

        this.updatePropertyLeaseCharges = this.updatePropertyLeaseCharges.bind(this);
        this.saveRecurringScheduleProcess = this.saveRecurringScheduleProcess.bind(this);
    }

    /**
     * Fetch the property lease on mounting of the component.
     */
    componentDidMount() {

        this.getOpenCharges(this.state.openChargesOnly);
        this.getPropertyDetails();
    }


    /**
     * Fetches additional tenant and company information
     * 
     * @param propertyLease - The property lease.
     */
    fetchAdditionalInfo(propertyLease) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${propertyLease.companyId}/fallbackfields`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLease: propertyLease,
                autopayCharges: lodash.cloneDeep(propertyLease.charges),
                company:propertyLease.joins.company,
                integrationId: response.data.integrationId,
                customChargeCodes: response.data.chargeCodes,
            }));

            // Fetch the integrated balance flag from the integration
            if(response.data.integrationId) {
                axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${response.data.integrationId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        integratedBalance: response.data.integratedBalance,
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });
            }

            // Fetch accepted payment methods
            this.searchAcceptedPaymentTypes();

        }).catch(error => {
            this.handleValidation(error);
        });

        this.getRecurringSchedule();
    }

    /**
     * Search for a list of billing accounts based on a certain condition.
     */
    searchBillingAccounts() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
            orderBy: 'ASC',
            orderByFields: ["c.firstName"],
            conditionList: [
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "operator": "EQUALS",
                    "fieldName": "id",
                    "fieldValue": this.props.propertyLeaseId
                }
            ],
            joins: {
                "c": {
                    "targetRecordType": "TYPE_CUSTOMER",
                    "joinField": "userId",
                    "alias": "c",
                    "returnFields": [
                        "firstName",
                        "lastName",
                        "email",
                    ]
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed']
                }
            }
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records?.length) {
                const propertyLease = response.data?.records[0];
                this.fetchAdditionalInfo(propertyLease);
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch the open charges in addition to the property lease and associated company information for integration
     * purposes.
     *
     * @param openOnly - Indicate.
     */
    getOpenCharges(openOnly) {

        this.setState({
            spinner: true,
            openChargesOnly : openOnly
        });

        // Fetch property lease and custom charge codes
        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.propertyLeaseId
                }
            ],
            joins: {
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                },
                customer: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'customer',
                    returnFields: ['firstName', 'lastName']
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed', 'enforceCustomAmount', 'softMaximumPaymentAmount']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data?.records?.length) {
                const propertyLease = response.data.records[0];
                this.fetchAdditionalInfo(propertyLease);
            } else {
                // Checking if API property
                this.searchBillingAccounts();
            }
            
        }).catch(error => {
            this.handleValidation(error);
        });

        let chargesQuery = {

                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'billingAccountId',
                        operator: 'EQUALS',
                        fieldValue: this.props.propertyLeaseId
                    },
            ]
        };

        if(openOnly){
            chargesQuery.conditionList.push({
                type: 'NUMBER',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'balance',
                operator: 'NOT_EQUALS',
                fieldValue: 0
            });
        }
        else {
            let startDate = new Date();
            startDate.setMonth(startDate.getMonth()-12);
            startDate.setDate(1);
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            chargesQuery.conditionList.push({
                type: 'DATE',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'postMonth',
                operator: 'GREATER_THAN_OR_EQUALS',
                fieldValue: moment(startDate).format('YYYY-MM-DD[T]hh:mm:ssZZ')
            });
        }

        // Show skeleton loader until the charges are being fetched
        this.setState(prevState => ({
            ...prevState,
            openChargesLoading: true
        }));

        // Fetch open charges
        axios.post(`${constants.REACT_APP_HOST_API_URL}/open_charge/search`, chargesQuery, {
            headers: this.generateRequestHeaders()
        }).then(response => {


            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                openCharges: response.data.records
            }));

            this.getCurrentBalance();

        }).catch(error => {
            this.setState(prevState => ({
                ...prevState,
                openChargesLoading: false,
            }));
            this.handleValidation(error);
        });
    }


    /**
     * Fetch information related to the recurring schedule associated with the property lease, along with it's payment
     * method and next scheduled payment information, if available.
     */
    getRecurringSchedule() {

        this.setState(prevState => ({
            ...prevState,
            recurringScheduleLoading: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'billingAccountId',
                    operator: 'EQUALS',
                    fieldValue: this.props.propertyLeaseId
                }
            ],
            joins: {
                creditCard: {
                    targetRecordType: 'TYPE_CREDIT_CARD',
                    joinField: 'paymentMethodId',
                    alias: 'creditCard',
                    returnFields: ['brand', 'last4']
                },
                bankAccount: {
                    targetRecordType: 'TYPE_BANK_ACCOUNT',
                    joinField: 'paymentMethodId',
                    alias: 'bankAccount',
                    returnFields: ['last4']
                },
                payPal: {
                    targetRecordType: 'TYPE_PAY_PAL',
                    joinField: 'paymentMethodId',
                    alias: 'payPal',
                    returnFields: ['firstName', 'lastName', 'email']
                },
                wallet: {
                    targetRecordType: 'TYPE_WALLET',
                    joinField: 'paymentMethodId',
                    alias: 'wallet',
                    returnFields: ['walletType']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let recurringSchedule = response.data.records[0];
            if(recurringSchedule) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${recurringSchedule.id}/nextpayment`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        recurringSchedule: recurringSchedule,
                        recurringScheduleLoading: false,
                        recurringScheduleCustomAmt : {customAmtEnabled : recurringSchedule.customAmount ? true : false},
                        nextScheduledPayment: response.data,
                        spinner: false
                    }));

                }).catch(error => {
                    this.setState(prevState => ({
                        ...prevState,
                        recurringScheduleLoading: false
                    }));
                    this.handleValidation(error);
                });

                axios.get(`${constants.REACT_APP_HOST_API_URL}/${recurringSchedule?.paymentMethodType?.substring(5).toLowerCase()}/${recurringSchedule.paymentMethodId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        paymentMethod: response.data,
                        spinner: false
                    }));

                }).catch(error => {
                    const err = error.response.data;
                    if(err !== undefined && err.errorCode === "RM_ERROR_RECORD_NOT_FOUND") {
                        // if we are here, the payment method was deleted
                        this.setState((prevState)=>({
                            ...prevState,
                            isRecurringPaymentMethodPresent: false,
                        }));
                    }
                    this.handleValidation(error);
                });
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    recurringScheduleLoading: false,
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize the recurring schedule create/edit flow by pulling a list of payment methods associated with the user,
     * and triggering the recurring schedule modal.
     */
    initRecurringSchedule() {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.state.propertyLease?.userType?.substring(5).toLowerCase()}/${this.state.propertyLease.userId}/paymentmethods`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodList: response.data,
                recurringStartDate: prevState.recurringSchedule.startDate,
                autopayCharges: lodash.cloneDeep(prevState.propertyLease.charges),
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        $('#recurring-schedule').modal('show');
    }

    /**
     * update charges for the property lease
     */
    updatePropertyLeaseCharges() {
        const propertyLease = lodash.cloneDeep(this.state.propertyLease);
        propertyLease.charges = this.state.autopayCharges;
        const headers = this.generateRequestHeaders();
        const sessionId = window.ka?.sessionId;
        if (sessionId){
            headers.blueSnapSessionId = sessionId;
        }
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, [propertyLease]
        , {
            headers: headers
        })
    }

    /**
     * Create or update an existing recurring schedule.
     * ! TODO - This function can be optimized further to avoid extra API calls 
     * @param event - The event container.
     */
    saveRecurringSchedule(event) {
        event.preventDefault();
        if(this.state.company?.manageBalance !== 'YES') {
            let autopayChargesValidity = true;
            const autopayCharges = this.state.autopayCharges;
            autopayChargesValidity = autopayCharges.every((autopayCharge)=>{
                return autopayCharge.amount !== "" && autopayCharge.code !== "" && autopayCharge.name !== "";
            });
            if(!autopayChargesValidity) {
                this.setErrorMessage('Error - Please make sure that all the autopay charges are set correctly.');
                return;
            }
            // ! TODO in future for optimization - Check if the charges have actually changed to avoid unnecessary calls. 
            this.updatePropertyLeaseCharges().then((response)=>{
                this.setState(prevState => ({
                    ...prevState,
                    propertyLease: response.data[0],
                }));
                this.saveRecurringScheduleProcess(); 
            }).catch((error)=>{
                this.handleValidation(error);
            });
        } else {
            this.saveRecurringScheduleProcess(); 
        }
        
    }

    /**
     * Handle create or update recurring schedule.
     *
     */
    saveRecurringScheduleProcess() {

        this.setState({
            spinner: true
        });

        let params = {
            startDate: this.state.recurringStartDate,
            paymentMethodId: this.state.paymentMethod.id,
            paymentMethodType: this.state.paymentMethod.type
        }

        //update custom amount
        if(this.state.recurringSchedule.customAmount && (this.state.recurringScheduleCustomAmt.customAmtEnabled || this.state.company?.enforceCustomAmount)) {
            params.customAmount = this.state.recurringSchedule.customAmount;
        } else {
            params.customAmount = null;
        }

        if(this.state.recurringSchedule.id) {

            axios.patch(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${this.state.recurringSchedule.id}/update`, params, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                    isRecurringPaymentMethodPresent: true,
                }));

                this.getRecurringSchedule();

                $('#recurring-schedule').modal('hide');
            }).catch(error => {
                //Validate if the error occurs when the tenant has partial payment blocked and Enforce Custom Amount is Enabled
                if(this.state.propertyLease.blockPartialPayments && this.state.company.enforceCustomAmount){
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'danger',
                                message: `Conflict of hierarchy with ‘Enforce Custom Payments’ (Company Setting) and ‘Block Partial Payments’ (Tenant Lease Setting). Please disable either of the settings on Company or Tenant level.`
                            },
                        }],
                    }));
                    $('#recurring-schedule').animate({ scrollTop: 0 }, 'fast');
                }else{
                
                    this.handleValidation(error);
                    window.scrollTo(0, 0);
                    $('#recurring-schedule').modal('hide');
                }    

            });

        }

        if(!this.state.recurringSchedule.id) {

            params = {
                type: 'TYPE_RECURRING_SCHEDULE',
                userType: this.state.propertyLease.userType,
                userId: this.state.propertyLease.userId,
                startDate: this.state.recurringStartDate,
                recurrenceType: 'MONTHLY',
                paymentMethodId: this.state.paymentMethod.id,
                paymentMethodType: this.state.paymentMethod.type,
                billingAccountId: this.state.propertyLease.id,
                billingAccountType: this.state.propertyLease.type,
                charges: this.state.charges,
            }

            //set custom amount
            if(this.state.recurringSchedule.customAmount && (this.state.recurringScheduleCustomAmt.customAmtEnabled || this.state.company?.enforceCustomAmount)) {
                params.customAmount = this.state.recurringSchedule.customAmount;
            } else {
                params.customAmount = null;
            }

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, params, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }]
                }));

                this.getRecurringSchedule();

                $('#recurring-schedule').modal('hide');

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });

        }
    }

    /**
     * Fetch the current overall balance of the property lease.
     */
    getCurrentBalance() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/open_charge/search`, {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'billingAccountId',
                    operator: 'EQUALS',
                    fieldValue: this.props.propertyLeaseId
                },
                {
                    type: 'NUMBER',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'balance',
                    operator: 'NOT_EQUALS',
                    fieldValue: 0
                },
            ],
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let currentBalance = 0;

            response.data.records.forEach((charge, index) => {
                currentBalance += charge.balance;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                openChargesLoading: false,
                balanceCharges: response.data.records,
                currentBalance: currentBalance
            }));

        }).catch(error => {
            this.setState(prevState => ({
                ...prevState,
                openChargesLoading: false,
            }));
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of an open charge when the user indicates they want to add a new charge to the
     * statement.
     */
    initOpenCharge() {

        this.setState(prevState => ({
            ...prevState,
            charge: {
                ...prevState.charge,
                code: '',
                name: '',
                amount: '',
                balance: '',
                // postMonth: '',
            },
            validationList: [],
        }));

        $("#create-open-charge").modal("show");
    }

    /**
     * Initialize a new instance of an open credit when the user indicates they want to add a new credit
     */
    initOpenCredit() {
        this.setState(prevState => ({
            ...prevState,
            charge: {
                code: 'credit',
                name: '',
                amount: '',
                balance: '',
                postMonth: moment(new Date()).format('YYYY-MM-DD[T]hh:mm:ssZZ')
            },
            validationList: [],
        }));

        $("#create-open-credit").modal("show");
    }

        /**
     * Select an existing open charge for editing.
     *
     * @param charge - The selected open charge.
     */
    selectOpenCharge(charge) {

        this.setState(prevState => ({
            ...prevState,
            charge: charge,
            validationList: [],
        }));

        $("#edit-open-charge").modal("show");
    }

    /**
     * Create a new open charge.
     */
    saveOpenCharge(isCredit, event) {

        event.preventDefault();

        this.setState({
            spinner: true,
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, {
            ...this.state.charge,
            type: 'TYPE_OPEN_CHARGE',
            code: this.state.charge.code,
            name: this.state.charge.name,
            amount: isCredit ?  this.state.charge.amount * -1 :  this.state.charge.amount,
            postMonth: this.state.charge.postMonth,
            userId: this.state.propertyLease.userId,
            userType: this.state.propertyLease.userType,
            billingAccountId: this.state.propertyLease.id,
            billingAccountType: this.state.propertyLease.type,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.created'
                    },
                    values: {
                        firstName: this.state.propertyLease.joins.customer.firstName,
                        lastName: this.state.propertyLease.joins.customer.lastName,
                        postMonth: moment(this.state.charge.postMonth).format('MMMM YYYY')
                    }
                }],
            });

            this.getOpenCharges(this.state.openChargesOnly);

            $("#create-open-charge").modal("hide");
            $("#create-open-credit").modal("hide");

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Delete the selected open charge.
     */
    deleteOpenCharge() {

        this.setState({
            spinner: true,
        });

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/open_charge/${this.state.charge.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                charge: {
                    code: '',
                    name: '',
                    amount: '',
                    balance: '',
                    postMonth: '',
                },
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.deleted'
                    },
                    values: {
                        firstName: this.state.propertyLease.joins.customer.firstName,
                        lastName: this.state.propertyLease.joins.customer.lastName,
                    }
                }],
            }));

            this.getOpenCharges(this.state.openChargesOnly);

            $("#edit-open-charge").modal("hide");

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Add a new blank charge to the list of autopayCharges.
    */
    addAutopayCharge() {
        this.setState(prevState => ({
            ...prevState,
            autopayCharges: [...prevState.autopayCharges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }
    
    /**
     * Remove a specific charge from the list of autopayCharges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeAutopayCharge(index) {
        const autopayCharges = this.state.autopayCharges;
        autopayCharges.splice(index, 1);
        this.setState(prevState => ({
            ...prevState,
            autopayCharges: autopayCharges,
        }));
    }

    /**
     * Import recurring and open charges from the integrated system to the property lease currently being viewed.
     */
    importCharges() {

        this.setState({
            spinner: true,
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.state.propertyLease.id}/importcharges`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.imported'
                    },
                    values: {
                        firstName: this.state.propertyLease.joins.customer.firstName,
                        lastName: this.state.propertyLease.joins.customer.lastName,
                    }
                }],
            });

            this.getOpenCharges(this.state.openChargesOnly);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Import charges and amounts from the integrated system.
     */
    importAutopayCharges() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/recurringcharges`, {
            propertyId: this.state.propertyLease.joins?.property?.propertyIdentifier,
            tenantId: this.state.propertyLease.accountNumber,
            integrationId: this.state.integrationId,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    autopayCharges: response.data,
                }));
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Manually post the recurring charges of the property lease to the tenant for the current post month.
     */
    postRecurringCharges() {

        this.setState({
            spinner: true,
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/postcharges/${this.state.propertyLease.id}`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.posted'
                    },
                    values: {
                        firstName: this.state.propertyLease.joins.customer.firstName,
                        lastName: this.state.propertyLease.joins.customer.lastName,
                    }
                }],
            });

            this.getOpenCharges(this.state.openChargesOnly);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Create a new instance of a payment method, with the object dependant upon which payment method type the user
     * selects.
     *
     * @param paymentType - The type of payment method selected.
     */
    initPaymentMethod(paymentType) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    userId: this.state.propertyLease.userId,
                    userType: this.state.propertyLease.userType
                },
                showBillingAddressForm: false,
                validationList: []
            }));

            this.searchBillingAddresses();

            $('#recurring-schedule').modal('hide');
            $(`#${paymentType.replace("_", "-")}`).modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Save the selected payment method. Performs a list save, collecting at most the payment method, and billing
     * address.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#credit-card').modal('hide');
            $('#confirm-unionpay').modal('show');

            return null;
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let saveListData = [];

        // Add the billingAddress state to the save list queue if the new billing address form is shown
        if(this.state.showBillingAddressForm) {
            saveListData.push(billingAddress);
        }

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }

        // Handle institution and transit number conversions to routing number for Canadian banks
        if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

        // Add the paymentMethod state to the save list queue
        saveListData.push(paymentMethod);

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));
        const headers = this.generateRequestHeaders();
        const sessionId = window.ka?.sessionId;
        if(sessionId) {
            headers.blueSnapSessionId = sessionId;
        }
        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
            headers: headers
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: response.data[this.state.showBillingAddressForm ? 1 : 0],
                showBillingAddressForm: false,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.method.created'
                    }
                }],
            }));

            this.searchBillingAddresses();
            this.initRecurringSchedule();

            $('#credit-card').modal("hide");
            $('#bank-account').modal("hide");

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
        window.scrollTo(0, 0);
    }

    /**
     * Search for all addresses of type 'BILLING' associated with the user.
     */
    searchBillingAddresses() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertyLease.userId
                }
            ]
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                billingAddressList: response.data.records,
            }));

            if(response.data.records.length === 0) {
                this.initBillingAddress();
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
    initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: this.state.propertyLease.userType,
                    userId: this.state.propertyLease.userId
                },
                paymentMethod: {
                    ...prevState.paymentMethod,
                    billingAddressId: response.data.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the active state of the recurring schedule.
     *
     * @param checked - True or false is the recurring schedule is active or not.
     */
    handleChangeRecurringScheduleStatus(checked) {

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${this.state.recurringSchedule.id}/update`, {
            active: checked
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.getRecurringSchedule();

        }).catch(error => {

            //Validate if the error occurs when   'Enforce Custom Amount' is set and ‘Block Partial Payments’ (Tenant Lease Setting) is set
           if(this.state.propertyLease.blockPartialPayments && this.state.company.enforceCustomAmount){
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            message: "Conflict of hierarchy with 'Enforce Custom Amount' (Company Setting) and 'Block Partial Payments' (Tenant Lease Setting). Please disable either of the settings on Company or Tenant level."
                        },
                    }],
                }));
            }else{
            
                this.handleValidation(error);
                
            }

        });
    }

    /**
     * Handle changes to the charge code field by also changing the name of the charge code.
     *
     * @param event - The event container.
     */
    handleChangeChargeCode(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            charge: {
                ...prevState.charge,
                code: event.target.value,
                name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
            }
        }));
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {

            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));

        } else {

            this.initBillingAddress();

        }
    }

    /**
     * Handle changes to the populate billing address checkbox, allowing the user to populate all the billing address
     * information automatically using the address of the property they are setting up auto payments for.
     *
     * @param event - The event container.
     */
    handleChangePopulateBillingAddress(event) {

        event.persist();

        let residentialAddress;
        let billingAddress;

        // Populate the billing address with the information in the customer's property
        if(event.target.checked) {

            residentialAddress = this.state.propertyLease.joins.property;
            billingAddress = this.state.billingAddress;

            billingAddress.city = residentialAddress.city;
            billingAddress.country = residentialAddress.country;
            billingAddress.postalCode = residentialAddress.postalCode;
            billingAddress.province = residentialAddress.province;
            billingAddress.street1 = residentialAddress.street1;
            billingAddress.street2 = residentialAddress.street2;
            billingAddress.suite = this.state.propertyLease.unit;
        }

        // Clear the billing address fields if the checkbox is unchecked
        if(!event.target.checked) {

            billingAddress = this.state.billingAddress;

            billingAddress.city = '';
            billingAddress.country = '';
            billingAddress.postalCode = '';
            billingAddress.province = '';
            billingAddress.street1 = '';
            billingAddress.street2 = '';
            billingAddress.suite = '';
        }

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
            billingAddress: billingAddress
        }));
    }

    /**
     * Search for all available accepted payment types once the billing account for the payment has been established.
     * Based on the accepted payment types, some payment methods on the payment method list may be disabled because
     * the appropriate merchant account is not set up.
     */
    searchAcceptedPaymentTypes() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.state.propertyLease.propertyId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let acceptedPaymentTypes;

            acceptedPaymentTypes = response.data.acceptedPaymentTypes;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.propertyLease.companyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                response.data.acceptedPaymentTypes.forEach((data, index) => {
                    if(!acceptedPaymentTypes.includes(data)) {
                        acceptedPaymentTypes.push(data);
                    }
                });

                let paymentMethodCount = 0;
                if(acceptedPaymentTypes.includes('TYPE_CASH')) {
                    this.setState(prevState => ({
                        ...prevState,
                        showCashPaymentMethod: true,
                    }));
                    paymentMethodCount +=1;
                }
                if(acceptedPaymentTypes.includes('TYPE_BANK_ACCOUNT')) {
                    this.setState(prevState => ({
                        ...prevState,
                        showBankPaymentMethod: true,
                    }));
                    paymentMethodCount +=1;
                }
                if(acceptedPaymentTypes.includes('TYPE_PAY_PAL')) {
                    paymentMethodCount +=1;
                }

                if(acceptedPaymentTypes.length > paymentMethodCount) {
                    this.setState(prevState => ({
                        ...prevState,
                        showCreditCardPaymentMethod: true,
                    }));
                }

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * This method will fetch the details associated with the currect property.
     */
    getPropertyDetails() {
        if(this.props.propertyLeaseId) {
            const propertyLeaseId = this.props.propertyLeaseId;
            axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${propertyLeaseId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                if(response.data && response.data.propertyId) {
                    const propertyId = response.data.propertyId;
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}/peek`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
                        if(response.data && response.data.country) {
                            const country = response.data.country;
                            this.setState(prevState => ({
                                ...prevState,
                                propertyCountry: country,
                            }));
                        }
                    });
                }
                if (response?.data?.blockPayments) {
                    this.setState(prevState => ({
                        ...prevState,
                        blockPayments: {
                            blockBankPayment: response.data.blockPayments['TYPE_BANK_ACCOUNT']?.blocked,
                            blockCreditPayment: response.data.blockPayments['TYPE_CREDIT_CARD']?.blocked,
                            blockPaypalPayment: response.data.blockPayments['TYPE_PAY_PAL']?.blocked,
                            blockCashPayment: response.data.blockPayments['TYPE_CASH']?.blocked,
                        },
                        // validationList: [{
                        //     fields: {},
                        //     alert: {
                        //         type: 'danger',
                        //         code: 'saved',
                        //         message: 'Bank account payments are blocked for the tenant. Please set up an alternative payment method to process auto payment.'
                        //     }
                        // }],
                        // isRecurringPaymentMethodPresent: true,
                    }));
                }
            }).catch(error => {
                this.handleValidation(error);
            });
        }  
    }

    /**
     * Render the component.
     *
     * @returns {*} - A component that allows the manager to manage a tenant's property lease and associated recurring
     * schedule.
     */
    render() {

        const {formatMessage} = this.props.intl;

        const ordinal = require('ordinal');

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                {this.state.isRecurringPaymentMethodPresent &&
                    <Alert validationList={this.state.validationList} />
                }

                {this.state.recurringSchedule.id && this.state.isRecurringPaymentMethodPresent && (this.props.userSession?.sessionRole?.type !== 'TYPE_MANAGER' || (this.props.userSession?.sessionRole?.roles?.includes('PRIMARY_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('SUPPORT_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('PROPERTY_MANAGER'))) &&
                <div className="card card-primary border-primary kaxsdc" data-event='load'>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8 align-self-center">
                                <div className="card-text">
                                    {(this.state.recurringSchedule.joins && this.state.recurringSchedule.joins.creditCard) &&
                                    <React.Fragment>
                                        Automatically bill this tenant every month for their current balance to their <span className="font-weight-bold">{this.state.recurringSchedule.joins.creditCard.brand ? formatMessage({id: "enum.creditCard.brand." + this.state.recurringSchedule.joins.creditCard.brand}) : 'Credit Card'} {this.state.recurringSchedule.joins.creditCard.last4 ? ' ending in ' + this.state.recurringSchedule.joins.creditCard.last4 : ''}</span>? <div className="mt-2 btn-link c-pointer" onClick={() => this.initRecurringSchedule()}>Edit Auto Pay <FontAwesomeIcon icon={['far', 'angle-right']} className="va-b" /></div>
                                    </React.Fragment>
                                    }
                                    {(this.state.recurringSchedule.joins && this.state.recurringSchedule.joins.bankAccount) &&
                                    <React.Fragment>
                                        Automatically bill this tenant every month for their current balance to their <span className="font-weight-bold">bank account {this.state.recurringSchedule.joins.bankAccount.last4 ? 'ending in ' + this.state.recurringSchedule.joins.bankAccount.last4 : ''}</span>? <div className="mt-2 btn-link c-pointer" onClick={() => this.initRecurringSchedule()}>Edit Auto Pay <FontAwesomeIcon icon={['far', 'angle-right']} className="va-b" /></div>
                                    </React.Fragment>
                                    }
                                    {(this.state.recurringSchedule.joins && this.state.recurringSchedule.joins.payPal) &&
                                    <React.Fragment>
                                        Automatically bill this tenant every month for their current balance to their <span className="font-weight-bold">Pay Pal account 
                                        
                                        {this.state.recurringSchedule.joins.payPal.firstName && 
                                            this.state.recurringSchedule.joins.payPal.lastName &&
                                            this.state.recurringSchedule.joins.payPal.email ?
                                         ' belonging to ' +  this.state.recurringSchedule.joins.payPal.firstName + ' ' + this.state.recurringSchedule.joins.payPal.lastName + ' - (' + this.state.recurringSchedule.joins.payPal.email + ')'
                                         : ''}
                                        
                                        </span>? <div className="mt-2 btn-link c-pointer" onClick={() => this.initRecurringSchedule()}>Edit Auto Pay <FontAwesomeIcon icon={['far', 'angle-right']} className="va-b" /></div>
                                    </React.Fragment>
                                    }
                                    {(this.state.recurringSchedule.joins && this.state.recurringSchedule.joins.wallet) &&
                                        <React.Fragment>
                                            Automatically bill this tenant every month for their current balance to their <span className="font-weight-bold">{this.state.recurringSchedule.joins.wallet.walletType ? formatMessage({id: "enum.wallet.walletType." + this.state.recurringSchedule.joins.wallet.walletType}) : 'Wallet'}</span>? <div className="mt-2 btn-link c-pointer" onClick={() => this.initRecurringSchedule()}>Edit Auto Pay <FontAwesomeIcon icon={['far', 'angle-right']} className="va-b" /></div>
                                        </React.Fragment>
                                    }
                                </div>
                                {this.state.nextScheduledPayment && !this.state.blockPayments.blockBankPayment &&
                                <p className="card-text small mb-0">
                                    Next scheduled payment: <Moment format="MMMM DD, YYYY" tz="UTC">{this.state.recurringSchedule.padSchedule?parseDateForPad(this.state.nextScheduledPayment.scheduledDate):this.state.nextScheduledPayment.scheduledDate}</Moment> - {this.state.nextScheduledPayment.status} {this.state.recurringSchedule.padSchedule && <span className="small badge badge-primary va-b c-help" data-toggle="tooltip" data-placement="top" title="Pre-authorized debit processing is active for this tenant's automatic payments">PAD</span> }
                                </p>
                                }
                            </div>
                            <div className="col-md-4 align-self-center text-right">
                                <FieldSwitch id="active" model="recurringSchedule" parent={this} value={this.state.recurringSchedule.active} handleChange={this.handleChangeRecurringScheduleStatus} />
                            </div>
                        </div>
                    </div>
                </div>
                }

                {(!this.state.recurringSchedule.id || !this.state.isRecurringPaymentMethodPresent) && (this.props.userSession?.sessionRole?.type !== 'TYPE_MANAGER' || (this.props.userSession?.sessionRole?.roles?.includes('PRIMARY_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('SUPPORT_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('PROPERTY_MANAGER'))) &&
                <div className="card card-primary border-primary">
                    <div className="card-body">
                        { this.state.recurringScheduleLoading ? <SkeletonLoader visible={true} skeletonForLoading={true} bodyRows={2}/> :  
                            <div className="row">
                                <div className="col-md-8 align-self-center">
                                    <p className="card-text mb-0">
                                        Set up automatic monthly payments for this tenant?
                                    </p>
                                </div>
                                <div className="col-md-4 align-self-center text-right">

                                    <div className="btn btn-primary btn-md mt-4 mt-md-0" onClick={() => this.initRecurringSchedule()}>
                                        Set Up Auto Pay <FontAwesomeIcon icon={['far', 'angle-double-right']} className="va-b" />
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
                }

                <div className="modal fade" id="recurring-schedule" tabIndex="-1" role="dialog" aria-labelledby="recurring-schedule-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveRecurringSchedule} autoComplete="off">

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="recurring-schedule-label">
                                        {this.state.recurringSchedule.id &&
                                        <React.Fragment>
                                            Edit Auto Pay
                                        </React.Fragment>
                                        }
                                        {!this.state.recurringSchedule.id &&
                                        <React.Fragment>
                                            Set Up Auto Pay
                                        </React.Fragment>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        {this.state.recurringSchedule.id &&
                                        <React.Fragment>
                                            Select the monthly payment start date and preferred payment method for this tenant's automatic monthly payments below.
                                        </React.Fragment>
                                        }
                                        {!this.state.recurringSchedule.id &&
                                        <React.Fragment>
                                            To set up automatic monthly payments for this tenant, select the monthly payment start date and preferred payment method below.
                                        </React.Fragment>
                                        }
                                    </p>
                                </div>

                                <div className="modal-body">
                                    {this.state.isRecurringPaymentMethodPresent &&
                                        <Alert validationList={this.state.validationList} validationType="danger" />
                                    }
                                    <FieldDate id="recurringStartDate" label="Start Date" filterDateType={this.state.propertyLease.monthlyPaymentDueDay > 0 ? 'MONTHLY_PAYMENT_DUE_DAY' : ''} monthlyPaymentDueDay={this.state.propertyLease.monthlyPaymentDueDay} labelClass="col-form-label-md d-none" placeholder="Start Date" fieldClass="form-control-md" fieldColumns="12" labelColums="0" parent={this} minDate={this.addDays(new Date(), 1)} help="The tenant will be charged every month starting on your selected date." value={this.state.recurringStartDate}>
                                        {this.state.propertyLease.monthlyPaymentDueDay > 0 &&
                                        <div className="text-center font-weight-bold py-2">
                                            Payment is due by<br />the {ordinal(this.state.propertyLease.monthlyPaymentDueDay)} of each month
                                        </div>
                                        }
                                    </FieldDate>

                                    {this.state.company?.manageBalance !== 'YES' &&
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <FieldCharges 
                                                    customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} 
                                                    charges={this.state.autopayCharges} 
                                                    addCharge={this.addAutopayCharge} 
                                                    removeCharge={this.removeAutopayCharge} 
                                                    importCharges={this.state.integrationId && this.state.propertyLease?.accountNumber ? this.importAutopayCharges : null} size="sm" parent={this} 
                                                    parentStateName='autopayCharges'
                                                />
                                            </div>
                                        </div>
                                    }

                                    {this.state.company.manageBalance === 'YES' &&
                                        !this.state.propertyLease.blockPartialPayments &&
                                        <React.Fragment>
                                            <FieldCheckbox
                                                id="customAmtEnabled"
                                                fieldColumns={12}
                                                fieldLabel="Enable custom auto payment amount"
                                                parent={this}
                                                model="recurringScheduleCustomAmt"
                                                value={this.state.recurringScheduleCustomAmt.customAmtEnabled || this.state.company?.enforceCustomAmount}
                                            />

                                            <FieldText 
                                                id="customAmount"
                                                label="Custom Amount"
                                                labelClass="col-form-label-md"
                                                iconType="fas"
                                                iconName="dollar-sign"
                                                fieldClass="form-control-md"
                                                fieldColumns="10"
                                                labelColumns="2"
                                                type="number"
                                                min="1.00"
                                                max="999999.99"
                                                step="0.01"
                                                pattern="[0-9]+(\.[0-9][0-9]?)?"
                                                parent={this}
                                                required={this.state.company?.enforceCustomAmount}
                                                model="recurringSchedule"
                                                value={(this.state.recurringScheduleCustomAmt.customAmtEnabled || this.state.company?.enforceCustomAmount) ? this.state.recurringSchedule.customAmount : null}
                                                disabled={!this.state.recurringScheduleCustomAmt.customAmtEnabled && !this.state.company?.enforceCustomAmount}
                                                help="Letus will always take this amount to process the auto payment, irrespective of the balance."
                                                warning={this.state.recurringSchedule.customAmount > this.state.company?.softMaximumPaymentAmount? <FormattedMessage id='static.error.soft.payment.threshold'
                                                                                                                                                                     values={{
                                                                                                                                                                         transactionAmount: this.state.recurringSchedule.customAmount,
                                                                                                                                                                         softThresholdValue: this.state.company?.softMaximumPaymentAmount
                                                                                                                                                                     }} /> :
                                                    (this.state.company?.enforceCustomAmount ? "You must specify a custom monthly amount in order to save the auto payment." : undefined)}
                                            />
                                        </React.Fragment>
                                    }

                                    {this.state.paymentMethodList.map((data, key) => {
                                        if((data.type && data.type === "TYPE_BANK_ACCOUNT") ? (this.state.propertyCountry && data.country && this.state.propertyCountry === data.country) : true) {
                                            if(data.type !== 'TYPE_CASH' && !(data.type === 'TYPE_WALLET' && !data?.last4)) {
                                                return (
                                                    <div key={key} className="list-group mb-2">
                                                        <div className={'custom-control custom-radio list-group-item list-group-item-action c-pointer ' + ((data.type === 'TYPE_CREDIT_CARD' && this.state.blockPayments.blockCreditPayment) || (data.type === 'TYPE_BANK_ACCOUNT' && this.state.blockPayments.blockBankPayment) || (data.type === 'TYPE_PAY_PAL' && this.state.blockPayments.blockPaypalPayment) ? 'disabled' : '')}>
                                                            <input type="radio" name="paymentMethod" value={JSON.stringify(data)} id={key} checked={this.state.paymentMethod.id === data.id || false} onChange={this.handleChangePaymentMethod} className="custom-control-input"/>
                                                            <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Bank Account
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                <FormattedMessage id={"enum.creditCard.brand." + data.brand}/>
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_WALLET' &&
                                                                            <React.Fragment>
                                                                                <FormattedMessage id={"enum.wallet.walletType." + data.walletType}/>
                                                                            </React.Fragment>
                                                                            }
                                                                        </div>
                                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Account number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                Card number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_WALLET' &&
                                                                            <React.Fragment>
                                                                                Card number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right mr-2">
                                                                            <CardBrandIcon paymentMethodType={data.type} brand={data.brand} customClasses="w-75"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                    { this.state.showCreditCardPaymentMethod &&
                                        <div className="list-group mb-2">
                                            <div className={'list-group-item list-group-item-action c-pointer ' + (this.state.blockPayments.blockCreditPayment ? 'disabled' : '')} onClick={() => this.initPaymentMethod('credit_card')}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Credit or Debit Card
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    { this.state.showBankPaymentMethod &&
                                        <div className="list-group">
                                            <div className={'list-group-item list-group-item-action c-pointer ' + (this.state.blockPayments.blockBankPayment ? 'disabled' : '')} onClick={() => this.initPaymentMethod('bank_account')}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Bank Account
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                        </div>
                                        <div className="col-6 text-right">

                                            {this.state.paymentMethodList.length > 0 &&
                                            <button type="submit" className="btn btn-primary btn-lg ml-2"
                                                disabled={((this.state.paymentMethod?.type === 'TYPE_CREDIT_CARD' && this.state.blockPayments?.blockCreditPayment) || (this.state.paymentMethod?.type === 'TYPE_BANK_ACCOUNT' && this.state.blockPayments?.blockBankPayment) || (this.state.paymentMethod?.type === 'TYPE_PAY_PAL' && this.state.blockPayments?.blockPaypalPayment) || (this.state.company?.manageBalance !== 'YES' && !this.state.autopayCharges.length) || false)}
                                                >Save</button>
                                            }

                                            {this.state.paymentMethodList.length === 0 &&
                                            <div className="btn btn-primary btn-lg ml-2 disabled" data-toggle="tooltip" data-placement="top" title="In order to set up automatic monthly payments for this tenant, they must have at least one payment method on file">Save</div>
                                            }

                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="card">

                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Unpaid Charges
                            </div>
                            <div className="col text-right">

                                <div className="dropdown">

                                    {this.state.company.manageBalance === 'YES' &&
                                        <div className="btn btn-primary btn-sm dropdown-toggle" role="button" id="statement-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Actions
                                        </div>
                                    }

                                    <div className="dropdown-menu" aria-labelledby="statement-actions">

                                        {this.props.issueChargesButton &&
                                        <div data-toggle="modal" data-target="#post-monthly-charges" className="dropdown-item c-pointer">
                                            <FontAwesomeIcon icon={['fas', 'mailbox']} className="fa-fw" /> Post Monthly Charges
                                        </div>
                                        }

                                        {(this.state.integrationId && this.state.propertyLease.accountNumber && this.state.integratedBalance) &&
                                        <div className="dropdown-item c-pointer" data-toggle="modal" data-target="#import-charges">
                                            <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Import Charges
                                        </div>
                                        }

                                        <div className="dropdown-item c-pointer" onClick={() => this.initOpenCharge()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Add One-Time Charge
                                        </div>
                                        <div className="dropdown-item c-pointer" onClick={() => this.initOpenCredit()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Add One-Time Credit
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    
                    { this.state.openChargesLoading ? <SkeletonLoader visible={true} skeletonForLoading={true}/> :
                        <>
                            {this.state.openCharges.length > 0 &&
                            <div className="card-body card-body-table">
                                <table className="table table-hover table-bordered table-responsive-sm small">
                                    <thead>
                                    <tr>
                                        <th width="15%">
                                            Post Date
                                        </th>
                                        <th width="15%">
                                            Period
                                        </th>
                                        <th width="25%">
                                            Description
                                        </th>
                                        <th width="15%">
                                            Amount
                                        </th>
                                        <th width="15%">
                                            Payment
                                        </th>
                                        <th width="15%">
                                            Amount Due
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {this.state.openCharges.map((openCharge, key) => {
                                        return (
                                            <tr key={key} onClick={() => this.selectOpenCharge(openCharge)} className="c-pointer">
                                                <td>
                                                    <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                            {openCharge.createDate}
                                                        </Moment>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <Moment format="MMM YYYY" tz="UTC">{openCharge.postMonth}</Moment>
                                                        {(moment(new Date()).isAfter(openCharge.dueDate) && openCharge.amount > 0 && openCharge.balance !== 0) && <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="fa-fw text-danger ml-1" data-toggle="tooltip" data-html={true} data-placement="top" title={`This charge is overdue`} /> }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {this.state.customChargeCodes ? openCharge.name : formatMessage({id: `charge.${openCharge.code}`})}
                                                        {openCharge.recurring && <FontAwesomeIcon icon={['fas', 'repeat']} className="fa-fw text-primary ml-1" data-toggle="tooltip" data-placement="top" title="This is a recurring charge that is automatically issued to the tenant every month" /> }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <FormattedNumber value={openCharge.amount} style={`currency`} currency="USD" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <FormattedNumber value={openCharge.amount-openCharge.balance} style={`currency`} currency="USD" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <FormattedNumber value={openCharge.balance} style={`currency`} currency="USD" />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}

                                    </tbody>
                                </table>
                            </div>

                            }

                            {this.state.openCharges.length === 0 &&
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-secondary">
                                            <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x" />
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                This tenant does not have any unpaid charges.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </>
                    }
                    <div className="card-body card-body-table">
                        <table className="table table-hover table-bordered table-responsive-sm small">
                            <tbody>
                            <tr className="bg-secondary">
                                <td colSpan="3" width="85%">
                                    <div className="">
                                        Balance Due<FontAwesomeIcon icon={['fas', 'question-circle']} className="fa-fw" data-toggle="tooltip" data-placement="top" title="The current balance reflects the overall balance due for the tenant" />
                                    </div>
                                </td>
                                <td>
                                    <div className="font-weight-bold">
                                        <FormattedNumber value={this.state.currentBalance} style={`currency`} currency="USD" />
                                    </div>
                                </td>
                            </tr>

                                <tr className="c-pointer">
                                    <td colSpan="6" className="text-center" >
                                        {this.state.openChargesOnly ?
                                            <div className="btn-link" onClick={() => this.getOpenCharges(false)}>
                                                Show 12 month History <FontAwesomeIcon icon={['fas', 'caret-down']} className="fa-fw"/>
                                            </div>
                                            :
                                            <div className="btn-link" onClick={() => this.getOpenCharges(true)}>
                                                Hide History <FontAwesomeIcon icon={['fas', 'caret-up']} className="fa-fw"/>
                                            </div>
                                        }
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="modal fade" id="edit-open-charge" tabIndex="-1" role="dialog" aria-labelledby="edit-open-charge-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                                <div className="">
                                    <FontAwesomeIcon icon={['far', 'usd-circle']} className="fa-fw va-b mb-3" size="4x" />
                                </div>
                                <h5 className="modal-title" id="edit-open-charge-label">
                                    {this.state.charge.name}
                                </h5>

                            </div>

                            <div className="modal-body modal-body-table">
                                <table className="table">

                                    <tbody>

                                    <React.Fragment>
                                        <tr>
                                            <td className="">
                                                <div className="">
                                                    Charge Code
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.charge.code}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="">
                                                <div className="">
                                                    Billing Period
                                                </div>
                                            </td>
                                            <td className="text-right">

                                                <div className="">
                                                    <Moment format="MMMM YYYY" tz="UTC">{this.state.charge.postMonth}</Moment>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="">
                                                <div className="">
                                                    Due Date
                                                    {(moment(new Date()).isAfter(this.state.charge.dueDate) && this.state.charge.amount > 0 && this.state.charge.balance !== 0) &&
                                                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="fa-fw text-danger ml-1" data-toggle="tooltip" data-html={true} data-placement="top" title={`This charge is overdue`} />
                                                    }
                                                </div>

                                            </td>
                                            <td className="text-right">

                                                <div className="">
                                                    <Moment format="MMMM DD, YYYY" tz="UTC">{this.state.charge.dueDate}</Moment>
                                                </div>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="">
                                                <div className="">
                                                    Amount
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    <FormattedNumber value={this.state.charge.amount} style={`currency`} currency="USD" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="">
                                                <div className="">
                                                    Payment/Credit
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    <FormattedNumber value={this.state.charge.amount-this.state.charge.balance} style={`currency`} currency="USD" />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="">
                                                <div className="">
                                                    Amount Due
                                                </div>
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    <FormattedNumber value={this.state.charge.balance} style={`currency`} currency="USD" />
                                                </div>
                                            </td>
                                        </tr>

                                    </React.Fragment>

                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer d-block bg-secondary rounded-bottom d-block">

                                <div className="row">
                                    <div className="col">

                                        <div className="float-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg my-2" data-dismiss="modal">Close</button>
                                        </div>

                                        <div className="float-right">

                                            {(this.state.charge.amount === this.state.charge.balance) &&
                                            <div className="btn btn-primary btn-lg my-2" data-toggle="modal" data-target="#delete-charge" onClick={() => $("#edit-open-charge").modal('hide')}>Delete</div>
                                            }

                                            {(this.state.charge.amount !== this.state.charge.balance) &&
                                            <div className="btn btn-primary btn-lg disabled my-2" data-toggle="tooltip" data-placement="top" title="This charge has already been paid or partially paid for, and cannot be deleted">Delete</div>
                                            }

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="create-open-charge" tabIndex="-1" role="dialog" aria-labelledby="create-open-charge-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={(e) => {this.saveOpenCharge(false, e)}}>

                                <div className="modal-header bg-dark text-white border-bottom-0">
                                    <h5 className="modal-title" id="create-open-charge-label">
                                        Add One-Time Charge
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldSelect id="code" label="Charge" model="charge" parent={this} disabled={this.state.charge.external} value={this.state.charge['code']} handleChange={this.handleChangeChargeCode}>

                                        <option value="" disabled={true}>Select a charge...</option>

                                        {this.state.customChargeCodes.length > 0 &&
                                        <React.Fragment>
                                            {this.state.customChargeCodes.map((data, key) => {
                                                return(
                                                    <option key={key} value={data.code}>{data.name}</option>
                                                )
                                            })}
                                        </React.Fragment>
                                        }

                                        {this.state.customChargeCodes.length === 0 &&
                                        <React.Fragment>
                                            {Object.keys(charges).map(key => {
                                                return(
                                                    <option key={key} value={key}>{formatMessage({id: `charge.${key}`})}</option>
                                                )
                                            })}
                                        </React.Fragment>
                                        }

                                    </FieldSelect>

                                    <FieldText id="chargeAmount" placeholder="0.00" type="number" iconType="fas" iconName="dollar-sign" label="Amount" model="charge" step="0.01" min="1" pattern="[0-9]" disabled={this.state.charge.createDate} parent={this} value={this.state.charge['amount']} />

                                    <FieldBirthDate id="postMonth" dayDisabled={true} label="Period" model="charge" parent={this} value={this.state.charge['postMonth']} createDate={this.state.propertyLease.createDate} optional={false} monthFirst={true}/>


                                </div>
                                <div className="modal-footer bg-secondary d-block rounded-bottom">

                                    <div className="row">
                                        <div className="col">

                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>

                                            <div className="float-right">

                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Save</button>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="create-open-credit" tabIndex="-1" role="dialog" aria-labelledby="create-open-credit-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={(e) => {this.saveOpenCharge(true, e)}}>

                                <div className="modal-header bg-dark text-white border-bottom-0">
                                    <h5 className="modal-title" id="create-open-credit-label">
                                        Add One-Time Credit
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="name" label="Description" model="charge" parent={this} value={this.state.charge['name']} />

                                    <FieldText id="creditAmount" placeholder="0.00" type="number" iconType="fas" iconName="dollar-sign" label="Amount" model="charge" step="0.01" min="1" pattern="[0-9]" disabled={this.state.charge.createDate} parent={this} value={this.state.charge['amount']} />


                                </div>
                                <div className="modal-footer bg-secondary d-block rounded-bottom">

                                    <div className="row">
                                        <div className="col">

                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>

                                            <div className="float-right">

                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Save</button>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                { this.state.showCreditCardPaymentMethod &&
                    <div className="modal fade" id="credit-card" tabIndex="-1" role="dialog" aria-labelledby="card-card-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.savePaymentMethod}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="card-card-label">
                                            Add New Credit or Debit Card
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        {!this.state.paymentMethod.createDate &&
                                        <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} columnClasses="col-8"/>
                                        }

                                        <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                        <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                        <div className="row mb-3">
                                            <div className="col">

                                                <FieldSelect id="expiryMonth" label="Expiry Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                    <option value="">-</option>
                                                    <option value="1">01 - Jan</option>
                                                    <option value="2">02 - Feb</option>
                                                    <option value="3">03 - Mar</option>
                                                    <option value="4">04 - Apr</option>
                                                    <option value="5">05 - May</option>
                                                    <option value="6">06 - Jun</option>
                                                    <option value="7">07 - Jul</option>
                                                    <option value="8">08 - Aug</option>
                                                    <option value="9">09 - Sep</option>
                                                    <option value="10">10 - Oct</option>
                                                    <option value="11">11 - Nov</option>
                                                    <option value="12">12 - Dec</option>
                                                </FieldSelect>

                                            </div>
                                            <div className="col">

                                                <FieldSelect id="expiryYear" label="Expiry Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                    <option value="">-</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                </FieldSelect>

                                            </div>
                                            <div className="col">

                                                <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['securityCode']} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />

                                            </div>
                                        </div>

                                        {!this.state.showBillingAddressForm &&
                                        <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                            <option value="">Select from your billing addresses...</option>

                                            {this.state.billingAddressList.map((data, key) => {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                    </option>
                                                );
                                            })}

                                            <option value="NEW">Add new billing address...</option>

                                        </FieldSelect>
                                        }

                                        {this.state.showBillingAddressForm &&
                                        <div className="mb-2">

                                            <div className="mt-4 mb-3">
                                                <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress}/>
                                            </div>

                                            <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} isForCreditCard={true}/>

                                        </div>
                                        }

                                    </div>
                                    <div className="modal-footer d-block bg-secondary rounded-bottom">

                                        <div className="row">
                                            <div className="col">
                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#credit-card').modal('hide'); $('#recurring-schedule').modal('show');}}>Back</button>
                                                </div>
                                                <div className="float-right">
                                                    <ButtonSave />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }   
                { this.state.showBankPaymentMethod &&
                    <div className="modal fade" id="bank-account" tabIndex="-1" role="dialog" aria-labelledby="bank-account-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.savePaymentMethod}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="bank-account-label">
                                            Add New Bank Account
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body bg-secondary">
                                        <p className="mb-0">
                                            <small>
                                                Upon confirming a payment or when your scheduled payment date arrives, you will be charged immediately, but it takes up to 5 business days for the funds to be withdrawn from your bank account (your property manager is aware of this). Please ensure sufficient funds are in your account prior to the payment being debited.
                                            </small>
                                        </p>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldCountry id="country" label="Bank Country" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['country']} propertyCountry={this.state.propertyCountry} isForTenantBankAcc = {true}/>

                                        {this.state.paymentMethod['country'] &&
                                        <React.Fragment>

                                            {this.state.paymentMethod['country'] === 'CA' &&
                                            <React.Fragment>

                                                <FieldText id="bankAccountInstitutionNumber" label="Institution Number"
                                                        required={true} fieldColumns="12" labelColumns="12"
                                                        fieldClass="form-control-md" type="tel" parent={this}
                                                        value={this.state['bankAccountInstitutionNumber']}/>

                                                <FieldText id="bankAccountTransitNumber" label="Transit Number"
                                                        required={true}
                                                        fieldColumns="12" labelColumns="12" fieldClass="form-control-md"
                                                        type="tel" parent={this}
                                                        value={this.state['bankAccountTransitNumber']}/>

                                            </React.Fragment>
                                            }

                                            {this.state.paymentMethod['country'] === 'US' &&
                                            <React.Fragment>

                                                <FieldText id="routingNumber" label="Routing Number" model="paymentMethod"
                                                        required={true} fieldColumns="12" labelColumns="12"
                                                        fieldClass="form-control-md" type="tel" parent={this}
                                                        value={this.state.paymentMethod['routingNumber']}/>

                                            </React.Fragment>
                                            }

                                            <FieldText id="bankAccountNumber" label="Account Number" required={true}
                                                    model="paymentMethod" fieldColumns="12" labelColumns="12"
                                                    fieldClass="form-control-md" type="tel" parent={this}
                                                    value={this.state.paymentMethod['bankAccountNumber']}/>

                                            <FieldText id="accountHolder" label="Account Holder" required={true}
                                                    model="paymentMethod" fieldColumns="12" labelColumns="12"
                                                    fieldClass="form-control-md" parent={this}
                                                    value={this.state.paymentMethod['accountHolder']}/>

                                            {!this.state.showBillingAddressForm &&
                                            <FieldSelect id="billingAddressId" label="Billing Address" required={true}
                                                        model="paymentMethod" fieldColumns="12" labelColumns="12"
                                                        fieldClass="form-control-md" parent={this}
                                                        value={this.state.paymentMethod['billingAddressId']}
                                                        handleChange={this.handleChangeBillingAddress}>

                                                <option value="">Select from your billing addresses...</option>

                                                {this.state.billingAddressList.filter((data) => (data.country === "CA" || data.country === "US")).map((data, key) => {
                                                    return (
                                                        <option key={key} value={data.id}>
                                                            {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                        </option>
                                                    );
                                                })}

                                                <option value="NEW">Add new billing address...</option>

                                            </FieldSelect>
                                            }

                                            {this.state.showBillingAddressForm &&
                                            <div className="mb-2">

                                                <div className="mt-4 mb-3">
                                                    <FieldCheckbox id="populateBillingAddressForm"
                                                                fieldLabel="Billing address same as property lease"
                                                                fieldClass="form-control-sm" fieldColumns="12"
                                                                labelClass="col-form-label-sm px-2" parent={this}
                                                                value={this.state.populateBillingAddressForm}
                                                                handleChange={this.handleChangePopulateBillingAddress}/>
                                                </div>

                                                <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12"
                                                            fieldClass="form-control-md" suite={true} parent={this}
                                                            value={this.state.billingAddress}/>

                                            </div>
                                            }

                                        </React.Fragment>
                                        }

                                    </div>
                                    <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                        <div className="row">
                                            <div className="col">
                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#bank-account').modal('hide'); $('#recurring-schedule').modal('show');}}>Back</button>
                                                </div>
                                                <div className="float-right">
                                                    <ButtonSave />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>  
                }
                <ModalUnionPay parent={this} />

                <Modal id="import-charges" theme="primary" iconType="fas" iconName="question-circle" title="Import Charges" body="Importing charges from your integrated system will overwrite any one-time charges that have been previously applied to this tenant via Letus. Are you sure you want to import charges?">
                    <button type="button" className="btn btn-outline-primary btn-lg my-2" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.importCharges()}} className="btn btn-primary btn-lg my-2" data-dismiss="modal">
                        Import Charges
                    </button>
                </Modal>

                <Modal id="post-monthly-charges" theme="primary" iconType="fas" iconName="question-circle" title="Post Monthly Charges" body="Are you sure you would like to manually post this tenant's monthly charges for the current billing period?">
                    <button type="button" className="btn btn-outline-primary btn-lg my-2" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.postRecurringCharges()}} className="btn btn-primary btn-lg my-2" data-dismiss="modal">
                        Post Monthly Charges
                    </button>
                </Modal>

                <Modal id="delete-charge" theme="danger" iconType="fas" iconName="exclamation-triangle" title= "Delete Charge" body= "Are you sure you would like to delete this charge?">
                    <button type="button" className="btn btn-outline-danger btn-lg my-2" data-dismiss="modal" onClick={() => {$("#edit-open-charge").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteOpenCharge()}} className="btn btn-danger btn-lg my-2" data-dismiss="modal">
                        Delete Charge
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

OpenCharges.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(OpenCharges);
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import * as moment from "moment";
import React from 'react';
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { TYPE_COMMON } from "../../../constants/CommonConstants";
import CONTACT_OPTIONS from "../../../constants/TenantConstants";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonResend from "../../common/ButtonResend";
import ButtonSave from "../../common/ButtonSave";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldCharges from "../../common/FieldCharges";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldDate from "../../common/FieldDate";
import FieldPhone from "../../common/FieldPhone";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import FieldTextarea from "../../common/FieldTextarea";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import FieldSelectLanguage from "../../common/FieldSelectLanguage";

class Tenant extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            disableBlockPartialPayments:false,
            disableRequestForPay:false,
            disableRequestForPayReason:null,
            disableResendInvitation: false,

            propertyLease: {},
            customer: {},

            charges: [],
            customChargeCodes: [],

            integrationId: '',
            integrationCustomerList: [],
            integrationSearch: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                tenantId: '',
                propertyId: ''
            },
            customerPhoneOnPageLoad: '',

            payByText: {
                serviceEnabled: false,
            },
            
            validationList: [],
            spinner: false,

            blockPayments: {
                blockCreditPayment: false,
                blockBankPayment: false,
                blockPaypalPayment: false,
                blockCashPayment: false,
                blockSplitPayment: false,
            },
            recurringSchedule:{},
        };

        this.saveCustomer = this.saveCustomer.bind(this);

        this.initIntegrationCustomers = this.initIntegrationCustomers.bind(this);
        this.searchIntegrationCustomers = this.searchIntegrationCustomers.bind(this);
        this.confirmSendTextMessage = this.confirmSendTextMessage.bind(this);
        this.selectIntegrationCustomer = this.selectIntegrationCustomer.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
        this.importCharges = this.importCharges.bind(this);

        this.handleChangeAccountNumber = this.handleChangeAccountNumber.bind(this);

        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);

        this.fetchAdditionalInfo = this.fetchAdditionalInfo.bind(this);
        this.fetchCompanyPayByTextStatus = this.fetchCompanyPayByTextStatus.bind(this);
        this.fetchPropertyLease = this.fetchPropertyLease.bind(this);
        this.syncTenant = this.syncTenant.bind(this);
        this.requestPayment = this.requestPayment.bind(this);
        this.resyncTenant = this.resyncTenant.bind(this);
        this.checkForSystemAccount = this.checkForSystemAccount.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);

        this.getMostRecentMessage = this.getMostRecentMessage.bind(this);
        this.handleNotBlockBankPayments = this.handleNotBlockBankPayments.bind(this);
    }

    /**
     * Fetch the property lease and associated customer data on mounting of the component. Also pre-fill the property ID
     * field of the integrated tenant search with the relevant property identifier.
     */
    componentDidMount() {

        this.setState({
            spinner: true,
        });

        this.fetchPropertyLease();
        
    }


    /**
     * Validate if the button Request For Payment is enbaled or not
     */
    validateRequestForPayment() { 
        
        axios(`${constants.REACT_APP_HOST_API_URL}/textMessage/${this.state.propertyLease.id}/validate`, {
            method: 'GET',
            headers: this.generateRequestHeaders(),
        })
        .then(response => {
            if(response.data?.result?.length > 0 && response.data?.result === 'SUCCESS') {
                this.setState(prevState => ({
                    ...prevState,
                    disableRequestForPay: false
                }));
            }
        }).catch(error => {
            this.setState(prevState => ({
                ...prevState,
                disableRequestForPay: true,
                disableRequestForPayReason: error.response?.data?.message
            }));
        });
    }

    /**
     * Fetch property lease
     */
    fetchPropertyLease() {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['id', 'propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed', "enforceCustomAmount", "notificationProvider"]
                },
                textmessage: {
                    targetRecordType: "TYPE_TEXT_MESSAGE",
                    joinField: "id",
                    targetField: "leaseid",
                    alias: "textmessage",
                    returnFields: ['sentdatetime', 'status']
                },
                recurringSchedule: {
                    targetRecordType: "TYPE_RECURRING_SCHEDULE",
                    joinField: "id",
                    targetField: "billingAccountId",
                    alias: "recurringSchedule",
                    returnFields: ['billingAccountId', 'active']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data.records.length) {
                const propertyLease = response.data.records[0];
                const recurringSchedule = response.data.records[0].joins?.recurringSchedule;

                let mostRecentMessageDate = this.getMostRecentMessage(response?.data?.records);
                this.setState(prevState => ({
                    ...prevState,
                    sentDateTime: mostRecentMessageDate || null,
                    recurringSchedule: [recurringSchedule]
                })); 
         
                this.fetchAdditionalInfo(propertyLease);
            } else {
                this.searchBillingAccounts();
            }        
        }).catch(error => {

            this.handleValidation(error);
        });
    }

     /**
     * Get the most recent successfull message date .
     */
      getMostRecentMessage(data) {
          if (data.length > 0){
              let result = null;

              data.sort(function(a,b){
                 let first = a?.joins?.textmessage?.sentdatetime;
                 let second = b?.joins?.textmessage?.sentdatetime;
                 return moment(second).diff(moment(first));
              });
         
              data.every((record) => {
                if(record?.joins?.textmessage?.status === "SUCCESS"){
                    result = record?.joins?.textmessage?.sentdatetime;
                   return false;
                }else{
                    return true;
                }
              });

              return result;
          }
      }

    /**
     * Search for a list of billing accounts based on a certain condition.
     */
    searchBillingAccounts() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
            orderBy: 'ASC',
            orderByFields: ["c.firstName"],
            conditionList: [
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "operator": "EQUALS",
                    "fieldName": "id",
                    "fieldValue": this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                "c": {
                    "targetRecordType": "TYPE_CUSTOMER",
                    "joinField": "userId",
                    "alias": "c",
                    "returnFields": [
                        "firstName",
                        "lastName",
                        "email",
                    ]
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed']
                }
            }
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records?.length) { 
                const propertyLease = response.data?.records[0];
                this.fetchAdditionalInfo(propertyLease);
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Check if the tenant has local system account and if it's verfied. If both true, return true, else false.
     * @param userId - Customer record id
     */
    checkForSystemAccount(userId, muteNotification){

        let sysAccQuery = {
            orderBy: 'DESC',
            orderByFields: ["updateDate"],
            conditionList: [
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "operator": "EQUALS",
                    "fieldName": "userId",
                    "fieldValue": userId
                }
            ],
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/local_system_account/search?recordsPerPage=100&page=1`, sysAccQuery, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                localSystemAccount: response?.data?.totalRecordCount !== 0,
                disableResendInvitation: response?.data?.totalRecordCount !== 0 || muteNotification
            }));            
            
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /** 
     * Fetches additional tenant and company information
     * 
     * @param propertyLease - The property lease.
     */
    fetchAdditionalInfo(propertyLease) {
        let company = propertyLease.joins.company;
            let disableBlockPartialPayments = (company.manageBalance  === 'NO') && (company.overpaymentAllowed === 'YES');
            
            let blockPartialPayments = disableBlockPartialPayments ? false : propertyLease.blockPartialPayments;
            
            propertyLease.blockPartialPayments = blockPartialPayments;
            
            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${propertyLease.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let customer = response.data;

                this.setState(prevState => ({
                    ...prevState,
                    customer: customer,
                    customerPhoneOnPageLoad: customer.phone
                }));

                this.checkForSystemAccount(customer.id, customer.muteNotification);

            }).catch(error => {
                this.handleValidation(error);
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${propertyLease.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    propertyLease: propertyLease,
                    disableBlockPartialPayments: disableBlockPartialPayments,
                    integrationId: response.data.integrationId,
                    customChargeCodes: response.data.chargeCodes,
                    charges: propertyLease.charges,
                    integrationSearch: {
                        ...prevState.integrationSearch,
                        propertyId: propertyLease.joins?.property?.propertyIdentifier
                    },
                    blockPayments: {
                        blockCreditPayment: propertyLease.blockPayments['TYPE_CREDIT_CARD']?.blocked,
                        blockBankPayment: propertyLease.blockPayments['TYPE_BANK_ACCOUNT']?.blocked,
                        blockPaypalPayment: propertyLease.blockPayments['TYPE_PAY_PAL']?.blocked,
                        blockCashPayment: propertyLease.blockPayments['TYPE_CASH']?.blocked,
                        blockSplitPayment: propertyLease.blockPayments['TYPE_CREDIT_CARD']?.blockedSplit,
                    }
                }), () => {
                    this.fetchCompanyPayByTextStatus();
                    this.validateRequestForPayment();
                });

            }).catch(error => {
                this.handleValidation(error);
            });
    }

    /**
     * Handle the submission of the form by saving both the customer and property lease data.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
        });

        let customer = {
            ...this.state.customer
        };

        if(this.state.customerPhoneOnPageLoad !== this.state.customer.phone) {
            customer.phoneMetadata = null;
            customer.phoneStatus = null;
        }

        if(this.state.propertyLease?.joins?.company?.notificationProvider !== "LETUS") {
            //if the bank payment is blocked, also block text message notifications
            if(this.state.blockPayments.blockBankPayment || this.state.blockPayments.blockCreditPayment || this.state.blockPayments['blockPaypalPayment'] || this.state.blockPayments['blockCashPayment'] ||  this.state.blockPayments['blockSplitPayment']) {
                customer.muteTextMessageNotification = true;
            }
        }

        let propertyLease = lodash.cloneDeep(this.state.propertyLease);

        //Terminate lease if today's date is after end date
        if(new Date (Date.parse(propertyLease.endDate)) < new Date(Date.now())){
            propertyLease.status = 'TERMINATED';
        }

        propertyLease.blockPayments = {
            'TYPE_BANK_ACCOUNT': {
                blocked: this.state.blockPayments.blockBankPayment || false
            },
            'TYPE_CREDIT_CARD': {
                blocked: this.state.blockPayments.blockCreditPayment || false,
                blockedSplit: this.state.blockPayments.blockSplitPayment || false
            },
            'TYPE_PAY_PAL': {
                blocked: this.state.blockPayments.blockPaypalPayment || false
            },
            'TYPE_CASH': {
                blocked: this.state.blockPayments.blockCashPayment || false
            },
        }
        propertyLease.tenantNotes = this.state.propertyLease?.tenantNotes?.trim();
        propertyLease.internalNotes = this.state.propertyLease?.internalNotes?.trim();
        
        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, [
            customer,
            {
                ...propertyLease,
                charges: this.state.charges,
            }
        ], {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response)

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            }));

            this.props.refreshPropertyLease(this.props.match.params.propertyLeaseId);

            this.fetchPropertyLease();
        }).catch(error => {
            //Validate if the error occurs when the tenant has partial payments set for auto payments and Admin/Pm tries to block partial payment for the tenant lease
            const recurringPayments = this.state.recurringSchedule?.filter(payment => payment?.active === true);
            if(recurringPayments.length && this.state.propertyLease?.blockPartialPayments && this.state.company?.enforceCustomAmount){
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            message: `Conflict of hierarchy with ‘Enforce Custom Payments’ (Company Setting) and ‘Block Partial Payments’ (Tenant Lease Setting). Please contact customer support to disable ‘Enforce Custom Payments’ on Company Level if you want to block partial payments for this tenant.`
                        },
                    }],
                }))
            }else{
                this.handleValidation(error);
            }
        });

        window.scrollTo(0, 0);
    }

    /**
     * Initialize the search integration customers process by clearing out the search fields and revealing the search
     * integration customers modal.
     */
    initIntegrationCustomers() {

        this.setState(prevState => ({
            ...prevState,
            integrationCustomerList: [],
            integrationSearch: {
                ...prevState.integrationSearch,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                tenantId: ''
            }
        }));

        $('#property-lease').modal('hide');
        $('#search-tenant').modal('show');
    }

    
    /**
     * Send Text Message - Request For Payment
     */
     confirmSendTextMessage() {
      
        this.setState({
            spinner: true,
        });

        axios(`${constants.REACT_APP_HOST_API_URL}/textMessage/${this.state.propertyLease.id}/send`, {
            method: 'GET',
            headers: this.generateRequestHeaders(),
        })
        .then(response => {
            
            if(response.data?.result?.length > 0 && response.data?.result === 'SUCCESS') {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'admin.tenants.lease.integration.null'
                        }
                    }]
                });
            }
    
            $('#send-text').modal('hide');
            this.fetchPropertyLease();
        }).catch(error => {
            
            this.handleValidation(error);
            $('#send-text').modal('hide');
            window.scrollTo(0,0);
        });
    }

    /**
     * Perform a search for customers in the integrated system for selection.
     */
    searchIntegrationCustomers(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationCustomerList: [],
            validationList: []
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/search`, {
            email: this.state.integrationSearch.email,
            firstName: this.state.integrationSearch.firstName,
            lastName: this.state.integrationSearch.lastName,
            phone: this.state.integrationSearch.phone,
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.integrationSearch.tenantId,
            integrationId: this.state.integrationId,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationCustomerList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'admin.tenants.lease.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a customer from the integrated system. Using the data from the integrated system, the fields used to
     * invite or create a tenant will be pre-filled.
     *
     * @param data - The customer that has been selected from the integrated system.
     */
    selectIntegrationCustomer(data) {

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: data.externalId,
                unit: data.unit,
                startDate: (data?.leaseStartDate ? data.leaseStartDate: prevState.startDate),
                endDate: (data?.leaseEndDate ? data.leaseEndDate: prevState.endDate),
                status: (new Date (Date.parse(data.leaseEndDate)) < new Date(Date.now()) ? 'TERMINATED' : prevState.propertyLease?.status)
            },
            customer: {
                ...prevState.customer,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
            }
        }));

        $('#search-tenant').modal('hide');
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Import charges and amounts from the integrated system.
     */
    importCharges() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/recurringcharges`, {
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.propertyLease.accountNumber,
            integrationId: this.state.integrationId,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    // customChargeCodes: [...prevState.customChargeCodes, ...response.data],
                    charges: response.data,
                }));
            }

            if(response.data.length === 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.tenants.charges.null'
                        }
                    }]
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangeAccountNumber(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: event.target.value
            }
        }));
    }

    /**
     * Get the status of Txt2Pay for a company
     */
    fetchCompanyPayByTextStatus() {
    
        let id = null;
        let syncFor = null;
        
        if(this.state.propertyLease?.joins?.property?.id) {
            syncFor = TYPE_COMMON.TYPE_PROPERTY;
            id = this.state.propertyLease.joins.property.id;
        } else {
            return;
        }

        const headers = {
            headers: this.generateRequestHeaders()
        }
    
        axios.get(`${constants.REACT_APP_HOST_API_URL}/${syncFor}/${id}/pay-by-text/info`, headers)
        .then(response => {

            this.setState(prevState => ({
                ...prevState,
                payByText: {
                    serviceEnabled: response.data?.serviceEnabled
                }
            }));
        }).catch(error => {
            if(!error.response.data?.message?.includes('service is not configured')) {
                this.handleValidation(error);
                this.handleClearValidationList();
            }
        });
    }

    /**
     * Resync Tenant
     */
     resyncTenant() {

        const params = [this.state.propertyLease.id];

        axios.post(`${constants.REACT_APP_HOST_API_URL}/resync/tenant/${params}`, {
        }, {
            headers: this.generateRequestHeaders()
        }).then((response) => {
            window.scrollTo(0, 0);
            this.setCustomAlert("primary", "sync.started");
            this.fetchPropertyLease();
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * sync Tenant with Everyware
     */
    syncTenant() {

        if(!this.state.propertyLease.id) {
            return;
        }

        const params = [this.state.propertyLease.id];

        axios.post(`${constants.REACT_APP_HOST_API_URL}/resync/tenants`, {
            propertyLeaseIds: params,
        }, {
            headers: this.generateRequestHeaders()
        }).then((response) => {
            if(response?.data?.result === "One or more tenants that you have selected belongs to a property that has not had an initial tenant sync.  Please perform the full tenant sync initialized under Service Level first."){
                this.resyncTenant();
            } else{
                window.scrollTo(0, 0);
                this.setCustomAlert("primary", "sync.started");
                this.fetchPropertyLease();
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Request for payment, send text message
     */
     requestPayment() {
        if( this.state.sentDateTime === null || moment().diff(this.state.sentDateTime,'h') > 24 || typeof(this.state.sentDateTime) === "undefined"){
            this.confirmSendTextMessage();
        }else{
            $('#send-text').modal('show');
        }
        return;
    }

    /**
     * Resend setup invitation to the tenant
     */
    resendInvitation(invitationSendVia) {
        
        if(!this.state?.propertyLease?.id) {
            return;
        }

        const params = {
            invitationSendVia: invitationSendVia,
            propertyLeaseID: this.state?.propertyLease?.id,
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/resendsetupinvitation`, params, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                resendSetupInvitationResponse: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });

    }

    /**
     * Handle toggle of checkbox for 'Ignore the block triggered by a NSF'
     * If true, bank account payments cannot be blocked 
     * @param {*} event 
     */
    handleNotBlockBankPayments(event) {
        event.persist();
        const checked =  event.target?.checked;
        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                notBlockBankPayments: checked
            },
            blockPayments: {
                blockBankPayment: checked ? false : prevState.blockPayments.blockBankPayment,
            }
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The tenant account management component for landlords.
     */
    render() {
        
        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveCustomer}>

                    <div className="card">
                        <div className="card-header">
                            Tenant
                        </div>
                        <div className="card-body">
                            { this.state.propertyLease.commercial &&
                                <FieldText id="companyName" label="Company Name" model="propertyLease" parent={this} value={this.state.propertyLease['companyName']} />
                            }
                            <FieldText id="firstName" label="First Name" model="customer" parent={this} value={this.state.customer['firstName']} />

                            <FieldText id="lastName" label="Last Name" model="customer" parent={this} value={this.state.customer['lastName']} />

                            <FieldBirthDate id="birthDate" label="Birth Date" model="customer" parent={this} value={this.state.customer['birthDate']} monthFirst={true}/>

                            <FieldText id="email" label="Email" type="email" model="customer" parent={this} value={this.state.customer['email']} help={this.state.customer['email'] ? '' : 'Assign an email address to this tenant to allow them to log in.'} required={this.state.customer['preferredContactMethod']==='EMAIL'}/>

                            <FieldText id="userIdentifier" label="User Identifier" model="customer" parent={this} value={this.state.customer['userIdentifier']} />

                            <FieldPhone id="phone" label="Mobile Phone" model="customer" parent={this} value={this.state.customer['phone']} required={this.state.payByText.serviceEnabled || this.state.customer['preferredContactMethod']==='MOBILE_PHONE'} phoneStatus={this.state.customer?.phoneStatus}/>

                            <FieldPhone id="alternatePhone" label="Alternate Phone" model="customer" parent={this} value={this.state.customer['alternatePhone']} required={this.state.customer['preferredContactMethod']==='ALTERNATE_PHONE'}/>

                            <FieldSelect id="preferredContactMethod" label="Best Way To Contact" model="customer" parent={this} value={this.state.customer['preferredContactMethod']} required={this.state.payByText.serviceEnabled}>
                                <option value="">Select an option...</option>
                                {CONTACT_OPTIONS.map((contactOption, key) => 
                                            <option key={key} value={contactOption.value}>{contactOption.text}</option>
                                )}
                            </FieldSelect>

                            <FieldSelectLanguage id="languageCode" label="Language Default" model="customer" parent={this} value={this.state.customer['languageCode']}/>

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                    Account ID
                                </label>
                                <div className="col-sm-9">

                                    <div className="input-group input-group-sm">

                                        <input id="accountNumber" name="accountNumber" value={this.state.propertyLease['accountNumber'] || ""} className="form-control mb-0" onChange={this.handleChangeAccountNumber}/>

                                        {this.state.integrationId &&
                                        <div className="input-group-append">
                                            <div className={`btn btn-secondary ${this.state.propertyLease.propertyId ? '' : 'disabled'}`} onClick={() => this.initIntegrationCustomers()}>
                                                Search...
                                            </div>
                                        </div>
                                        }

                                    </div>
                                    { (this.state.propertyLease['accountNumber'] && this.state.propertyLease['status']==="TERMINATED") &&
                                        <small className="form-text text-muted-warning">
                                           <span role="img" aria-label="warning">⚠️</span> Please delete Account ID if it needs to be attached to a different lease.  
                                        </small>
                                    }

                                </div>
                            </div>

                            <FieldSelect id="status" label="Lease Status" model="propertyLease" parent={this} value={this.state.propertyLease['status']}>
                                <option disabled>Select a status...</option>
                                <option value="ACTIVE">Active</option>
                                <option value="SUSPENDED">Suspended</option>
                                <option value="TERMINATED">Terminated</option>
                            </FieldSelect>
                            <FieldText id="unit" label="Unit" model="propertyLease" parent={this} value={this.state.propertyLease['unit']} />

                            <FieldDate id="startDate" label="Lease Start Date" model="propertyLease" parent={this} value={this.state.propertyLease['startDate'] || null} />

                            <FieldDate id="endDate" label="Lease End Date" model="propertyLease" parent={this} value={this.state.propertyLease['endDate'] || null} before={new Date(this.state.propertyLease['startDate'])}/>

                            <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="propertyLease" parent={this} value={this.state.propertyLease['monthlyPaymentDueDay']}>
                                <option value={0}>No monthly payment due date</option>
                                <option value={1}>1st day of every month</option>
                                <option value={2}>2nd day of every month</option>
                                <option value={3}>3rd day of every month</option>
                                <option value={4}>4th day of every month</option>
                                <option value={5}>5th day of every month</option>
                                <option value={6}>6th day of every month</option>
                                <option value={7}>7th day of every month</option>
                                <option value={8}>8th day of every month</option>
                                <option value={9}>9th day of every month</option>
                                <option value={10}>10th day of every month</option>
                                <option value={11}>11th day of every month</option>
                                <option value={12}>12th day of every month</option>
                                <option value={13}>13th day of every month</option>
                                <option value={14}>14th day of every month</option>
                                <option value={15}>15th day of every month</option>
                                <option value={16}>16th day of every month</option>
                                <option value={17}>17th day of every month</option>
                                <option value={18}>18th day of every month</option>
                                <option value={19}>19th day of every month</option>
                                <option value={20}>20th day of every month</option>
                                <option value={21}>21st day of every month</option>
                                <option value={22}>22nd day of every month</option>
                                <option value={23}>23rd day of every month</option>
                                <option value={24}>24th day of every month</option>
                                <option value={25}>25th day of every month</option>
                                <option value={26}>26th day of every month</option>
                                <option value={27}>27th day of every month</option>
                                <option value={28}>28th day of every month</option>
                                <option value={29}>29th day of every month</option>
                                <option value={30}>30th day of every month</option>
                                <option value={31}>31st day of every month</option>
                            </FieldSelect>
                            
                            <FieldTextarea 
                                id="tenantNotes" 
                                label="Tenant Note" 
                                model="propertyLease" 
                                parent={this} 
                                maxLength={250}
                                help="This note would be visible to the tenant on their dashboard. Max length: 250"
                                value={this.state.propertyLease['tenantNotes']}/>

                            <FieldTextarea 
                                id="internalNotes" 
                                label="Internal Note" 
                                model="propertyLease" 
                                parent={this} 
                                maxLength={500}
                                help="This note is for internal use only and is not visible to the tenant. Max length: 500"
                                value={this.state.propertyLease['internalNotes']}/>

                            {/*
                            <div className="form-group row">
                                <div className="col-sm-3 col-form-label-sm">
                                    <div className="">
                                        Sync this tenant with Everyware
                                    </div>   
                                </div>
                                <div className="col-sm-9">
                                    <button
                                    type="button"
                                    className="btn btn-outline-primary btn-md mb-2"
                                    onClick={()=>this.syncTenant()}
                                    >
                                        <FontAwesomeIcon icon={['fas', 'sync']} className="fa-fw"/> {this.state.propertyLease?.syncEndTime && this.state.propertyLease?.syncStatus ? "Resync":"Sync"}
                                    </button>
                                    <div className="text-muted small">
                                        Tenant sync must be performed before Txt2Pay service can be enabled for this tenant.
                                    </div>
                                    { this.state.propertyLease?.syncEndTime && this.state.propertyLease?.syncStatus &&
                                        <div className="text-warning small">
                                            <span className="px-0 py-0">Last Sync performed on {moment(this.state.propertyLease?.syncEndTime).format("YYYY-MM-DD")}. Status - {this.state.propertyLease?.syncStatus}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                            */}

                            <div className="form-group row">
                                <div className="col-sm-3 col-form-label-sm">
                                    <div className="">
                                        Send text message
                                    </div>   
                                </div>
                                <div className="col-sm-9">
                                    {
                                        (this.state.propertyLease['status'] === 'TERMINATED' || this.state.propertyLease['status'] === 'SUSPENDED') &&
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-md mb-2"
                                                onClick={()=>{$("#send-text-message-to-terminated-lease").modal("show")}}
                                                disabled={this.state.disableRequestForPay}
                                            >
                                                Request for Payment
                                            </button>
                                        </div>
                                    }

                                    {
                                        (this.state.propertyLease['status'] !== 'TERMINATED' && this.state.propertyLease['status'] !== 'SUSPENDED') &&
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-md mb-2"
                                                onClick={()=>this.requestPayment()}
                                                disabled={this.state.disableRequestForPay}
                                            >
                                                Request for Payment
                                            </button>
                                        </div>
                                    }

                                    {
                                        this.state.disableRequestForPayReason  && 
                                        <span className="text-danger small">{' '}(Disabled because - {this.state.disableRequestForPayReason})</span>
                                    }
                                    { this.state.sentDateTime  &&
                                        <div className="text-warning small">
                                            <span className="px-0 py-0">Last Successfully Sent Message on {moment(this.state.sentDateTime).utc().format("YYYY-MM-DD")}.</span>
                                        </div>
                                    } 
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-sm-3 col-form-label-sm">
                                    <div className="">
                                        Resend Invitation
                                    </div>   
                                </div>
                                <div className="col-sm-9">
                                    <button
                                    type="button"
                                    className="btn btn-outline-primary btn-md mb-2"
                                    onClick={()=>this.resendInvitation("TEXT")}
                                    disabled={
                                        (this.state.propertyLease?.joins?.company?.notificationProvider !== 'LETUS' &&
                                        this.state.propertyLease?.syncStatus !== "COMPLETED")
                                        || this.state.customer?.phoneStatus !== "VALID"
                                    }
                                    >
                                       Resend setup invite via text message
                                    </button>
                                    { this.state.resendSetupInvitationResponse  &&
                                        <div className="text-warning small">
                                            <span className="px-0 py-0">{this.state.resendSetupInvitationResponse+"."}</span>
                                        </div>
                                    }
                                </div>
                            </div>

                            <FieldCheckbox 
                                id="blockPartialPayments" 
                                label="Partial Payments" 
                                fieldLabel="Block this tenant from making partial payments" 
                                model="propertyLease"
                                disabled={this.state.disableBlockPartialPayments} 
                                parent={this}
                                value={this.state.propertyLease['blockPartialPayments']} />

                            <FieldCheckbox 
                                id="blockBankPayment" 
                                label="Block Bank Payments"
                                fieldLabel="Block this tenant from making bank account payments"
                                model="blockPayments"
                                parent={this}
                                disabled={this.state.propertyLease?.notBlockBankPayments}
                                value={this.state.blockPayments['blockBankPayment']} />

                            <FieldCheckbox 
                                id="notBlockBankPayments" 
                                label=" "
                                fieldLabel="Ignore the block triggered by a NSF"
                                model="propertyLease"
                                parent={this}
                                handleChange={(event)=>this.handleNotBlockBankPayments(event)}
                                value={this.state.propertyLease['notBlockBankPayments']} />

                            <FieldCheckbox 
                                id="blockCreditPayment" 
                                label="Block Credit Card/Debit Card Payments"
                                fieldLabel="Block this tenant from making Credit Card/Debit Card payments"
                                model="blockPayments"
                                parent={this}
                                value={this.state.blockPayments['blockCreditPayment']} />
                            
                            <FieldCheckbox 
                                id="blockPaypalPayment" 
                                label="Block Paypal Payments"
                                fieldLabel="Block this tenant from making Paypal payments"
                                model="blockPayments"
                                parent={this}
                                value={this.state.blockPayments['blockPaypalPayment']} />

                            <FieldCheckbox 
                                id="blockCashPayment" 
                                label="Block Cash Payments"
                                fieldLabel="Block this tenant from making cash payments"
                                model="blockPayments"
                                parent={this}
                                value={this.state.blockPayments['blockCashPayment']} />

                            <FieldCheckbox 
                                id="blockSplitPayment" 
                                label="Block Splitit Payments"
                                fieldLabel="Block this tenant from making Splitit payments"
                                model="blockPayments"
                                parent={this}
                                value={this.state.blockPayments['blockSplitPayment']} />

                            <FieldCheckbox
                                id="muteNotification"
                                name="muteNotification"
                                label="Mute Notifications"
                                fieldLabel="Block email notifications to this tenant"
                                model="customer"
                                parent={this}
                                value={this.state.customer['muteNotification']}/>

                            <FieldCheckbox
                            id="muteTextMessageNotification"
                            name="muteTextMessageNotification"
                            label="Mute Text Message Notifications"
                            fieldLabel="Mute text messages to this tenant"
                            model="customer"
                            parent={this}
                            disabled={
                                (this.state.customer["muteTextMessageNotification"] &&
                                    this.state.customer?.textMessageNotificationMutedBy === "CUSTOMER") ||
                                (this.state.propertyLease?.joins?.company?.notificationProvider === "LETUS"
                                ? false
                                : this.state.blockPayments["blockBankPayment"] ||
                                    this.state.blockPayments["blockCreditPayment"] ||
                                    this.state.blockPayments["blockPaypalPayment"] ||
                                    this.state.blockPayments["blockCashPayment"] ||
                                    this.state.blockPayments["blockSplitPayment"])
                            }
                            value={
                                this.state.propertyLease?.joins?.company?.notificationProvider === "LETUS"
                                ? this.state.customer["muteTextMessageNotification"]
                                : this.state.customer["muteTextMessageNotification"] ||
                                    this.state.blockPayments["blockBankPayment"] ||
                                    this.state.blockPayments["blockCreditPayment"] ||
                                    this.state.blockPayments["blockPaypalPayment"] ||
                                    this.state.blockPayments["blockCashPayment"] ||
                                    this.state.blockPayments["blockSplitPayment"]
                            }
                            help={
                                this.state.customer["muteTextMessageNotification"] &&
                                this.state.customer?.textMessageNotificationMutedBy === "CUSTOMER"
                                ? "Tenant had sent STOP or UNSUBSCRIBE to disable text messages.  Tenant must reply to a previous text message with START or SUBSCRIBE in order to receive text messaging."
                                : undefined
                            }
                            />

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm">
                                    Monthly Charges
                                </label>
                                <div className="col-sm-9">
                                    <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={this.state.integrationId && this.state.propertyLease.accountNumber ? this.importCharges : null} size="sm" parent={this} />
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="row">
                        {this.state.customer && 
                            this.state.customer['email'] &&
                            this.state.customer['id'] &&
                            <div className="col text-left">
                                <ButtonResend   resendTitle="Invitation" disabled={this.state.disableResendInvitation} 
                                                customer={this.state.customer} parent={this} isInivitationResend={true}/>
                            </div>
                        }

                        <div className="col text-right">
                            <ButtonSave />
                        </div>
                    </div>

                </form>

                <div className="modal fade" id="search-tenant" tabIndex="-1" role="dialog" aria-labelledby="search-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.searchIntegrationCustomers}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        Search Tenants
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Select the tenant from your integrated system using the available search fields below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="tenantId" label="Account ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['tenantId']} />

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">or</span>
                                    </div>

                                    <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} disabled={true} value={this.state.integrationSearch['propertyId']} />

                                    <FieldText id="firstName" label="First Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['lastName']} />

                                    <FieldText id="email" label="Email" type="email" model="integrationSearch" parent={this} value={this.state.integrationSearch['email']} />

                                    <FieldText id="phone" label="Phone" type="tel" model="integrationSearch" parent={this} value={this.state.integrationSearch['phone']} />

                                </div>

                                {this.state.integrationCustomerList.length > 0 &&
                                <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                    <div className="card-body card-body-table">
                                        <table className="table table-bordered table-hover border">
                                            <thead>
                                            <tr>
                                                <th width="25%">Tenant</th>
                                                <th width="25%">Unit</th>
                                                <th width="25%">Account ID</th>
                                                <th width="25%">Property ID</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.integrationCustomerList.map((data, key) => {
                                                return (
                                                    <tr key={key} onClick={() => this.selectIntegrationCustomer(data)} className="c-pointer">
                                                        <td className="">
                                                            {data.firstName} {data.lastName}
                                                        </td>
                                                        <td className="">
                                                            {data.unit}
                                                        </td>
                                                        <td className="">
                                                            {data.externalId}
                                                        </td>
                                                        <td className="">
                                                            {data.externalPropertyId}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#create-tenant').modal('show')}>Back</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="send-text" tabIndex="-1" role="dialog" aria-labelledby="send-text-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="send-text-label">
                                        Send Text Message
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Text message has been sent to tenant within last 24 hours. Are you sure you want to send another message?
                                    </p>
                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#send-text').modal('hide')}>No</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg"  onClick={() => this.confirmSendTextMessage()}>Yes</button>
                                        </div>
                                    </div>

                                </div>

                            
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="send-text-message-to-terminated-lease" tabIndex="-1" role="dialog" aria-labelledby="send-text-message-to-terminated-lease-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">


                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="send-text-label">
                                    Send Text Message
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    This lease is suspended/terminated. Do you you want to proceed?
                                </p>
                            </div>

                            <div className="modal-footer bg-secondary rounded-bottom d-block">
                                <div className="row">
                                    <div className="col-6 text-left">
                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#send-text-message-to-terminated-lease').modal('hide')}><FormattedMessage id="button.no" /></button>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button type="submit" className="btn btn-primary btn-lg"  onClick={() => {$('#send-text-message-to-terminated-lease').modal('hide'); this.requestPayment()}}><FormattedMessage id="button.yes" /></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    };
}

Tenant.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenant);
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import $ from "jquery";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import { addTimeZone, convertUTCToTimezone } from "../../../util/dates";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldDateTime from "../../common/FieldDateTime";
import FieldDateTimeChargesScheduler from "../../common/FieldDateTimeChargesScheduler";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import FieldTime from "../../common/FieldTime";
import FieldTimezone from "../../common/FieldTimezone";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import FieldRadio from "../../common/FieldRadio";
import IntegrationForm from "../../common/IntegrationForm";

class Integrations extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            disabledIntegratedBalance: false,
            company: {},
            settings: {
                WhenToPush: 'REALTIME',
            },
            pushReversals: ['REFUND', 'RETURN', 'CHARGEBACK', 'NSF'],
            customSettings: [{}],
            validationList: [],
            batchRollverTime: '00:00',
            batchRollverExpression: '',
            batchRollverTextField: false,
            blackoutPeriod: {
                id: null,
                openDate: null,
                closeDate: null,
                active: false,
            },
            entrataTransExportSchedulerList: [],
            entrataTransReconcileSchedulerList: [],
            pullingChargesSchedulerList: [],
            pullingChargesScheduler: {
                pullingChargesActive: true,
            },
            pullingTenantsSchedulerList: [],
            pullingTenantsScheduler: {
                pullingTenantsActive: true,
            },
            pullingTransactionsSchedulerList: [],
            pullingPropertiesSchedulerList: [],
            pullingPropertiesScheduler: {
                pullingPropertiesActive: true,
            },
            pullPaidChargesBool: false,
            PullOpenChargesBool: true,
            pullLeaseDateBool: false,
            compressedConfirmationIdBool: false,
            enableCarryOverCharges: false,
            pullCoResidentsBool: false,
            pullNewResidentsBool: false,
            securityDepositCode: '',
            markReversalsPushingFailedBool: false,
            pushSecurityDepositsBool: false,
            pushWithChargeCodes: [],
            disableBatchCloserBool: false,
            postingBatchPeriodBool: false,
            pullOnlyExistingActiveLeaseBool: false,
        };

        this.initIntegration = this.initIntegration.bind(this);
        this.saveIntegration = this.saveIntegration.bind(this);
        this.testIntegration = this.testIntegration.bind(this);
        this.deleteIntegration = this.deleteIntegration.bind(this);

        this.addCustomIntegrationSetting = this.addCustomIntegrationSetting.bind(this);
        this.removeCustomIntegrationSetting = this.removeCustomIntegrationSetting.bind(this);
        this.handleChangeCustomIntegrationSettings = this.handleChangeCustomIntegrationSettings.bind(this);

        this.toggleBatchRollverInpField = this.toggleBatchRollverInpField.bind(this);

        this.getBlackoutPeriod = this.getBlackoutPeriod.bind(this);
        this.saveBlackoutPeriod = this.saveBlackoutPeriod.bind(this);

        this.handleRemoveSchedule = this.handleRemoveSchedule.bind(this);
        this.handleAddSchedule = this.handleAddSchedule.bind(this);

        this.getPullingSchedulerDefaultVal = this.getPullingSchedulerDefaultVal.bind(this);
        this.getPullingSchedulerList = this.getPullingSchedulerList.bind(this);
        this.savePullingSchedule = this.savePullingSchedule.bind(this);
        this.createPullingSchedule = this.createPullingSchedule.bind(this);
        this.updatePullingSchedule = this.updatePullingSchedule.bind(this);
        this.checkIfPullingScheduleToBeUpdated = this.checkIfPullingScheduleToBeUpdated.bind(this);

        this.calculateBooleanPushReversal = this.calculateBooleanPushReversal.bind(this);
        this.handleChangePushReversal = this.handleChangePushReversal.bind(this);

        this.handleClearValidationList = this.handleClearValidationList.bind(this);

        this.handleChangePullPaidCharges = this.handleChangePullPaidCharges.bind(this);
        this.handleChangeDisableBatchCloser = this.handleChangeDisableBatchCloser.bind(this);
        this.handleChangePullOpenCharges = this.handleChangePullOpenCharges.bind(this);
        this.handleChangeCompressedConfirmation = this.handleChangeCompressedConfirmation.bind(this);
        this.handleChangeCarryOverCharge = this.handleChangeCarryOverCharge.bind(this);
        this.handleChangePullCoResidents = this.handleChangePullCoResidents.bind(this);
        this.handleChangePullNewResidents = this.handleChangePullNewResidents.bind(this);
        this.handleChangeMarkReversalsPushingFailed = this.handleChangeMarkReversalsPushingFailed.bind(this);
        this.calculateBooleanMarkReversalsPushingFailed = this.calculateBooleanMarkReversalsPushingFailed.bind(this);
        this.handleChangePushSecurityDeposit = this.handleChangePushSecurityDeposit.bind(this);
        this.handleChangePushWithChargeCodes = this.handleChangePushWithChargeCodes.bind(this);
        this.calculateBooleanPushWithChargeCodes = this.calculateBooleanPushWithChargeCodes.bind(this);
        this.handleChangePostingBatchPeriod = this.handleChangePostingBatchPeriod.bind(this);
        this.handleChangePullOnlyExistingActiveLease = this.handleChangePullOnlyExistingActiveLease.bind(this);
    }

    /**
     * On mounting of the component, fetch the company and it's corresponding integration information if available for
     * displaying. If a custom integration is fetched, assign the settings of the integration to a local state value
     * for editing.
     */
    componentDidMount() {
        let disabledIntegratedBalance;

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            disabledIntegratedBalance = (response.data.manageBalance === 'NO') && (response.data.overpaymentAllowed === 'YES');

            this.setState(prevState => ({
                ...prevState,
                disabledIntegratedBalance: disabledIntegratedBalance,
                company: response.data
            }));

            if(response.data?.integrationId) {
                axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${response.data.integrationId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    let integration = response.data;

                    let integratedBalance = disabledIntegratedBalance ? false : integration.integratedBalance;

                    integration.integratedBalance = integratedBalance;

                    let customSettings = [{}];

                    if(response.data.integrationType === 'CUSTOM') {
                        Object.entries(response.data.settings).forEach(([customSettingKey, customSettingValue]) => {
                            customSettings.push({
                                customSettingKey: customSettingKey,
                                customSettingValue: customSettingValue
                            });
                        });
                    } else if(response.data?.integrationType === 'MRI' || 
                              response.data?.integrationType === 'MRI_COMMERCIAL' ||
                              response.data?.integrationType === 'SAP' ||
                              response.data?.integrationType === 'APPFOLIO') {

                        if(response.data?.settings?.BatchManagementSchedule) {
                            const batchManagementSchedule = response.data?.settings?.BatchManagementSchedule;
                            
                            if(batchManagementSchedule.match(/00?\s[0-9][0-9]?\s[0-9][0-9]?\s\*\s\*\s\*/g)) {
                                let min = batchManagementSchedule.split(" ")[1];
                                let hr = batchManagementSchedule.split(" ")[2];
                                if(min?.length === 1) {
                                    min = `0${min}`; // adjusting for html5 time picker
                                } 
                                if(hr?.length === 1) {
                                    hr = `0${hr}`; // adjusting for html5 time picker
                                }
                                this.setState(prevState => ({
                                    ...prevState,
                                    batchRollverTime: `${hr}:${min}`,
                                    batchRollverExpression: '',
                                    batchRollverTextField: false,
                                }));
                            } else {
                                this.setState(prevState => ({
                                    ...prevState,
                                    batchRollverTime: '00:00',
                                    batchRollverExpression: batchManagementSchedule,
                                    batchRollverTextField: true,
                                }));
                            }
                        } 
                    } 

                    if (response.data?.integrationType === 'MRI' || 
                        response.data?.integrationType === 'MRI_COMMERCIAL') {
                        // get the MRI integration blackout period
                        this.getBlackoutPeriod(response.data?.id, response.data?.timeZone);
                    }

                    const pushSettings = integration.pushSettings;
                    const pushSettingsArr = [];
                    
                    if(pushSettings["CHARGEBACK"] && pushSettings["CHARGEBACK"].PushReversals === "YES") {
                        pushSettingsArr.push("CHARGEBACK");
                    }
                    if(pushSettings["NSF"] && pushSettings["NSF"].PushReversals === "YES") {
                        pushSettingsArr.push("NSF");
                        
                    }
                    if(pushSettings["REFUND"] && pushSettings["REFUND"].PushReversals === "YES") {
                        pushSettingsArr.push("REFUND");
                        
                    }
                    if(pushSettings["RETURN"] && pushSettings["RETURN"].PushReversals === "YES") {
                        pushSettingsArr.push("RETURN");
                    }

                    let pullPaidChargesBoolean = response.data?.pullPaidCharges === 'YES';
                    let pullOpenChargesBoolean = response.data?.pullOpenCharges === 'YES';
                    let compressedConfirmationIdBoolean = response.data?.compressedConfirmationId === 'YES';
                    let enableCarryOverCharges = response.data.settings["EnableCarryOverCharges"] === 'true';
                    let pullCoResidentsBoolean = response.data?.settings?.PullCoResidents === 'YES';
                    let pullNewResidentsBoolean = response.data?.settings?.PullNewResidents === 'YES';
                    let markReversalsPushingFailedBoolean = response.data?.settings?.MarkReversalsPushingFailed === 'YES';
                    let disableBatchCloserBoolean = response.data?.settings?.DisableBatchCloser === 'true';
                    let postingBatchPeriodBoolean = response.data?.settings?.PostingBatchPeriod === 'true';
                    let pullOnlyExistingActiveLeaseBoolean = response.data?.pullOnlyExistingActiveLease === 'YES';

                    const pushWithChargeCodesSettingsArr = [];
                    if(pushSettings["PAYMENT"] && pushSettings["PAYMENT"].PushWithChargeCodes === "YES") {
                        pushWithChargeCodesSettingsArr.push("PAYMENT");
                        pushWithChargeCodesSettingsArr.push("CHARGEBACK");
                        pushWithChargeCodesSettingsArr.push("NSF");
                        pushWithChargeCodesSettingsArr.push("RETURN");
                        pushWithChargeCodesSettingsArr.push("REFUND");
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        integration: integration,
                        settings:{
                            ...response.data.settings,
                            WhenToPush: response.data.whenToPush
                        },
                        customSettings: customSettings,
                        pushReversals: pushSettingsArr,
                        pullPaidChargesBool: pullPaidChargesBoolean,
                        pullOpenChargesBool: pullOpenChargesBoolean,
                        pullLeaseDateBool: response.data?.settings?.PullLeaseDate === 'YES',
                        compressedConfirmationIdBool: compressedConfirmationIdBoolean,
                        enableCarryOverCharges: enableCarryOverCharges,
                        pullCoResidentsBool: pullCoResidentsBoolean,
                        pullNewResidentsBool: pullNewResidentsBoolean,
                        markReversalsPushingFailedBool: markReversalsPushingFailedBoolean,
                        pushWithChargeCodes: pushWithChargeCodesSettingsArr,
                        disableBatchCloserBool: disableBatchCloserBoolean,
                        postingBatchPeriodBool: postingBatchPeriodBoolean,
                        pullOnlyExistingActiveLeaseBool: pullOnlyExistingActiveLeaseBoolean,
                    }));

                    let pushSecurityDepositsBoolean = pushSettings["PAYMENT"] && pushSettings["PAYMENT"].PushSecurityDeposits && pushSettings["PAYMENT"].PushSecurityDeposits[0];
                    if(pushSecurityDepositsBoolean) {

                        let pushSecurityDeposits = pushSettings["PAYMENT"].PushSecurityDeposits[0];
                        this.setState(prevState => ({
                            ...prevState,
                            securityDepositCode: pushSecurityDeposits,
                            pushSecurityDepositsBool: true
                        }));

                    } else {

                        this.setState(prevState => ({
                            ...prevState,
                            securityDepositCode: '',
                            pushSecurityDepositsBool: false
                        }));
                    }

                }).catch(error => {
                    this.handleValidation(error);
                });

                this.getPullingSchedulerList(response.data?.integrationId);
            } else {
                this.setState(prevState => ({
                    ...prevState, 
                    pullingChargesSchedulerList: [this.getPullingSchedulerDefaultVal("OPEN_CHARGES_IMPORT")],
                    pullingTenantsSchedulerList: [this.getPullingSchedulerDefaultVal("TENANTS_IMPORT")],
                    pullingTransactionsSchedulerList: [this.getPullingSchedulerDefaultVal("TRANSACTIONS_PUSH")],
                    pullingPropertiesSchedulerList: [this.getPullingSchedulerDefaultVal("PROPERTIES_IMPORT")],
                    entrataTransExportSchedulerList: [this.getPullingSchedulerDefaultVal("ENTRATA_TRANSACTIONS_EXPORT")],
                    entrataTransReconcileSchedulerList: [this.getPullingSchedulerDefaultVal("ENTRATA_TRANSACTIONS_RECONCILE")]
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Clear validation list
     */
    handleClearValidationList() {
        this.setState(prevState => ({
            ...prevState,
            validationList: []
        }));
    }

    /**
     * Initialize a new instance of an integration object.
     */
    initIntegration() {

        let pullPaidChargesBoolean = false;
        axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(this.state.company.id){

                axios.post(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/search`, {
                    orderBy: 'DESC',
                    conditionList: [
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'companyId',
                            operator: 'EQUALS',
                            fieldValue: this.state.company.id
                        }
                    ]
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    if (response.data && response.data.records && response.data.records.length > 0) {
                        pullPaidChargesBoolean = true;
                    }
                }).catch(error => {});
            }

            this.setState(prevState => ({
                ...prevState,
                integration: response.data,
                pullPaidChargesBool: pullPaidChargesBoolean,
                pullOpenChargesBool: response.data.pullOpenCharges === "YES",
                compressedConfirmationIdBool: response.data.compressedConfirmationId === "YES",
                markReversalsPushingFailedBool: false,
                pullOnlyExistingActiveLeaseBool: response.data.pullOnlyExistingActiveLease === "YES"
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch an existing blackout period for MRI integration 
     * @param integrationId
     */
    getBlackoutPeriod(integrationId, currentTz) {

        axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${integrationId}/blackouts`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.length) {
                const dtFormat = "YYYY-MM-DD[T]HH:mm";
                const javaFormat = "YYYY-MM-DDTHH:mm:ssZZ";
                const latestBlackout = parseInt(response.data?.length) - 1;
                const blackoutId = response.data[latestBlackout]?.id;
                const openDate = convertUTCToTimezone(response.data[latestBlackout]?.openDate, javaFormat, currentTz, dtFormat);
                const closeDate = convertUTCToTimezone(response.data[latestBlackout]?.closeDate, javaFormat, currentTz, dtFormat);
                const status = response.data[latestBlackout]?.active;
                this.setState(prevState => ({
                    ...prevState,
                    blackoutPeriod: {
                        ...prevState.blackoutPeriod,
                        id: blackoutId,
                        openDate: openDate,
                        closeDate: closeDate, 
                        active: status,
                    }
                }));
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Setup a blackout period for MRI integration.
     * Transactions are not pushed to MRI during blackout period.  
     * Instead they are queued and sent on the day that Blackout Period is finished.
     * @param integrationId 
     */
    saveBlackoutPeriod(integrationId, timeZone) {

        const dtFormat = "YYYY-MM-DD[T]HH:mm";
        const javaFormat = "YYYY-MM-DDTHH:mm:ssZZ";
        const openDate = addTimeZone(this.state.blackoutPeriod.openDate, dtFormat, timeZone, javaFormat);
        const closeDate = addTimeZone(this.state.blackoutPeriod.closeDate, dtFormat, timeZone, javaFormat);
        
        const params = {
            "integrationId" : integrationId,
            "active": this.state.blackoutPeriod.active,  
        }

        if(this.state.blackoutPeriod.active) {
            params.openDate = openDate;
            params.closeDate = closeDate;
        }

        let method = null;
        let url = null;
        
        if(this.state.blackoutPeriod?.id) { 
            const blackoutId = this.state.blackoutPeriod?.id;
            method = 'PATCH';
            url = `${constants.REACT_APP_HOST_INTEGRATION_URL}/blackout/${blackoutId}/update`;
        } else { 
            method = 'POST';
            url = `${constants.REACT_APP_HOST_INTEGRATION_URL}/blackout/create`;
        }

        axios({
          method: method,
          url: url,
          data: params,
          headers: this.generateRequestHeaders(),
        })
          .then(() => {
            //updates the blackout period
            this.getBlackoutPeriod(integrationId, timeZone);
          })
          .catch((error) => {
            this.handleValidation(error);
          });
    }

    /**
     * Condition to check when integrated balance is unselected and atleast one 
     * charge scheduler is active
     * @return Boolean
     */
    preventSaveIntegration() {
        const canSave = this.canSaveIntegration();

        if(canSave) {
            this.resetChargesScheduler();
        }
        
        return canSave;
    }

    /**
     * This condition checks whether the rest of the logic
     * to select or delect charge schedulers
     */
    canSaveIntegration() {
        const charge = this.state.pullingChargesSchedulerList.find(charge => charge.active);
        return charge !== undefined && !this.state.integration['integratedBalance'];
    }

    /**
     * De-select all the values for charge schedulers
     */
    resetChargesScheduler() {
        const shouldReset = this.canSaveIntegration();

        if(shouldReset){
            let pullingChargesSchedulerList = this.state.pullingChargesSchedulerList.map(charge => {
                return {...charge, active: false };
            });

            this.setState(prevState => ({
                ...prevState,
                pullingChargesSchedulerList: pullingChargesSchedulerList
            }));
        }
    }

    /**
     * Create or save an existing integration object for the company being viewed.
     */
    saveIntegration(event) {

        event.preventDefault();

        if(this.preventSaveIntegration()) {
            $("#save-integration-check").modal("show");
            return;
        } else {
            this.resetChargesScheduler();
            $("#save-integration-check").modal("hide");
        }

        let integration = this.state.integration;

        integration.settings = this.state.settings;
        integration.active = true;
        integration.companyId = this.state.company.id;
        integration.companyName = this.state.company.name;

        const pushSecurityDepositsArr = [];
        if(this.state.pushSecurityDepositsBool && this.state.securityDepositCode){

            pushSecurityDepositsArr.push(this.state.securityDepositCode);
        }
        
        integration.pushSettings = {
            "REFUND": {
                "PushReversals": this.state.pushReversals?.includes("REFUND") ? "YES" : "NO",
                "PushWithChargeCodes": this.state.pushWithChargeCodes?.includes("REFUND") ? "YES" : "NO"
            },
            "RETURN": {
                "PushReversals": this.state.pushReversals?.includes("RETURN") ? "YES" : "NO",
                "PushWithChargeCodes": this.state.pushWithChargeCodes?.includes("RETURN") ? "YES" : "NO"
            },
            "CHARGEBACK": {
                "PushReversals": this.state.pushReversals?.includes("CHARGEBACK") ? "YES" : "NO",
                "PushWithChargeCodes": this.state.pushWithChargeCodes?.includes("CHARGEBACK") ? "YES" : "NO"
            },
            "NSF": {
                "PushReversals": this.state.pushReversals?.includes("NSF") ? "YES" : "NO",
                "PushWithChargeCodes": this.state.pushWithChargeCodes?.includes("NSF") ? "YES" : "NO"
            },
            "PAYMENT": {
                "PushSecurityDeposits": pushSecurityDepositsArr,
                "PushWithChargeCodes": this.state.pushWithChargeCodes?.includes("PAYMENT") ? "YES" : "NO"
            }
        }

        integration.settings["PullPaidCharges"] = this.state.pullPaidChargesBool ? 'YES' : 'NO';
        integration.settings["PullOpenCharges"] = this.state.pullOpenChargesBool ? 'YES' : 'NO';
        integration.settings["PullLeaseDate"] = this.state.pullLeaseDateBool ? 'YES' : 'NO'
        integration.settings["CompressedConfirmationId"] = this.state.compressedConfirmationIdBool ? 'YES' : 'NO';
        integration.settings["MarkReversalsPushingFailed"] = this.state.markReversalsPushingFailedBool ? 'YES' : 'NO';
        integration.settings["DisableBatchCloser"] = this.state.disableBatchCloserBool;
        integration.settings["PostingBatchPeriod"] = this.state.postingBatchPeriodBool;
        integration.settings["PullOnlyExistingActiveLease"] = this.state.pullOnlyExistingActiveLeaseBool ? 'YES' : 'NO';

        if(integration.integrationType === 'MRI_COMMERCIAL'){
            integration.settings["CompressedConfirmationId"] = 'YES';
        }
        
        if(integration.integrationType === 'RENTMANAGER') {
            integration.settings['EnableCarryOverCharges'] = this.state.enableCarryOverCharges;
        }

        if(integration.integrationType === 'CUSTOM') {
            this.state.customSettings.forEach((data, key) => {
                integration.settings[data.customSettingKey] = data.customSettingValue;
            });
        } else if((integration?.integrationType === 'MRI' ||
                   integration?.integrationType === 'MRI_COMMERCIAL' ||
                   integration?.integrationType === 'SAP' ||
                   integration?.integrationType === 'APPFOLIO') &&  integration.settings) {
            if(integration?.integrationType === 'MRI'){

                integration.settings['PullCoResidents'] = this.state.pullCoResidentsBool ? 'YES' : 'NO';
                integration.settings['PullNewResidents'] = this.state.pullNewResidentsBool ? 'YES' : 'NO';
            }

            if(this.state.batchRollverTextField) {
                integration.settings["BatchManagementSchedule"] = this.state.batchRollverExpression;
            } else if(!this.state.batchRollverTextField) {
                let cronExpression = '';
                if(this.state.batchRollverTime) {
                    const timeArr = this.state.batchRollverTime?.split(':');
                    cronExpression = `0 ${timeArr[1]} ${timeArr[0]} * * *`;
                } 
                integration.settings["BatchManagementSchedule"] = cronExpression;
            }

            if(this.state.blackoutPeriod.active && (!this.state.blackoutPeriod.openDate || !this.state.blackoutPeriod.closeDate)) {
                window.scrollTo(0, 0);
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'required.dates.range',
                        },
                        values: {
                            field: 'blackout period',
                        }
                    }],
                }));

                setTimeout(() => {
                    // Clears the error after three seconds
                    this.handleClearValidationList();
                }, 3000);

                return;
            }
        } else if(integration?.integrationType === 'RENTMANAGER' &&  integration.settings) { 
            integration.settings["LocationId"] = "1";
        }

        if(integration?.integrationType === 'QUICKBOOKS' && integration.settings['AUTHORIZED_EXTERNAL'] === undefined) {
            integration.settings['AUTHORIZED_EXTERNAL'] = 'false';
        }
        
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        if(!this.state.company.integrationId) {

            axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/create`, integration, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    integration: response.data
                }));

                if(this.state.blackoutPeriod.openDate && this.state.blackoutPeriod.closeDate) {
                    //save blackout period
                    this.saveBlackoutPeriod(response.data?.id, integration.timeZone);
                } else {
                    this.getBlackoutPeriod(response.data?.id, integration.timeZone);
                }

                axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/update`, {
                    integrationId: response.data.id
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        company: response.data,
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'primary',
                                code: 'admin.companies.integrations.created'
                            }
                        }],
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });

                this.savePullingSchedule(response.data.id);
            }).catch(error => {
                this.handleValidation(error);
            });

        }

        if(this.state.company.integrationId) {

            axios.put(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/update`, integration, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                }));

                if(this.state.blackoutPeriod.openDate && this.state.blackoutPeriod.closeDate) {
                    //save blackout period
                    this.saveBlackoutPeriod(this.state.company.integrationId, integration.timeZone);
                } else {
                    this.getBlackoutPeriod(this.state.company.integrationId, integration.timeZone);
                }
                this.savePullingSchedule(this.state.company.integrationId);
            }).catch(error => {
                this.handleValidation(error);
            });

            setTimeout(() => {

                //re-do integration update in order to make integration change work on another instance
                axios.put(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/update`, integration, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    // skip
                }).catch(error => {
                    // skip;
                });

            }, 500);
        }

        window.scrollTo(0, 0);
    }

    /**
     * Test an integration to ensure fields are validated.
     */
    testIntegration() {

        let integration = this.state.integration;

        integration.settings = this.state.settings;
        integration.active = true;
        integration.companyId = this.state.company.id;
        integration.companyName = this.state.company.name;

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        if(integration?.integrationType === 'RENTMANAGER' &&  integration.settings) { 
            integration.settings["LocationId"] = "1";
            integration["pluginClass"] = "RmRentManagerPlugin";
        }

        if(integration?.integrationType === 'PROPERTYWARE' &&  integration.settings) { 
            integration["pluginClass"] = "PropertyWarePlugin";
        }

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/test`, integration, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.integrations.tested'
                    }
                }],
            });
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Set the integration ID value of the company to null. This does not delete the integration itself.
     */
    deleteIntegration() {
         if(this.state.company.integrationId){

             axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${this.state.company.integrationId}`, {
                 headers: this.generateRequestHeaders()
             }).then(response =>{

                 let integration = response.data;
                 integration.active = false;

                 axios.put(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/update`, integration, {
                     headers: this.generateRequestHeaders()
                 });
             });

             axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/update`, {
                 integrationId: null
             }, {
                 headers: this.generateRequestHeaders()
             }).then(response => {
                 this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    company: response.data,
                    integration: null,
                    disabledIntegratedBalance: false,
                    settings: {
                        WhenToPush: 'REALTIME'
                    },
                    customSettings: [{}],
                    batchRollverTime: '00:00',
                    batchRollverExpression: '',
                    batchRollverTextField: false,
                    blackoutPeriod: {
                        id: null,
                        openDate: null,
                        closeDate: null,
                        active: false,
                    },
                    pullingChargesSchedulerList: [],
                    pullingChargesScheduler: {
                        pullingChargesActive: true,
                    },
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.companies.integrations.deleted'
                        }
                    }],
                 }));
             }).catch(error => {
                 this.handleValidation(error);
             });
        }
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCustomIntegrationSetting() {

        this.setState(prevState => ({
            ...prevState,
            customSettings: [...prevState.customSettings, {
                customSettingKey: '',
                customSettingValue: ''
            }]
        }));
    }

    /**
     * Remove a specific setting from the list of custom settings.
     *
     * @param index - The array index of the custom setting to remove.
     */
    removeCustomIntegrationSetting(index) {

        let customSettings = this.state.customSettings;

        customSettings.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            customSettings: customSettings
        }));
    }

    /**
     * Handle changes to the selected custom integration setting. Handles both the setting key and value fields for each
     * setting.
     *
     * @param event - The event container.
     */
    handleChangeCustomIntegrationSettings(event) {

        let customSettings = this.state.customSettings;

        let chargeIdSplit = (event.target.name).split("-");
        let chargeIdIndex = chargeIdSplit[chargeIdSplit.length - 1];

        // Handle key changes
        if(event.target.name.split('-')[2] === 'key') {
            customSettings[chargeIdIndex] = {
                customSettingKey: event.target.value,
                customSettingValue: customSettings[event.target.name.substr(-1)].customSettingValue
            };
        }

        // Handle value changes
        if(event.target.name.split('-')[2] === 'value') {
            customSettings[chargeIdIndex] = {
                customSettingKey: customSettings[event.target.name.substr(-1)].customSettingKey,
                customSettingValue: event.target.value
            };
        }

        this.setState(prevState => ({
            ...prevState,
            customSettings: customSettings
        }));
    }

    /**
     * Toggle between Batch Rollover input field types.
     */
    toggleBatchRollverInpField() {
        this.setState(prevState => ({
            ...prevState,
            batchRollverTextField: !prevState.batchRollverTextField,
        }));
    }

    /**
     * Handle change for the FieldRadio component
     * @param {*} event - Synthetic event
     */
    handleChangeWhenToPushField(event){

        event.persist();

        let targetValue = event.target.value;

        if(targetValue === "REALTIME" ||  targetValue === "SETTLED"){
            this.setState(prevState=>({
                ...prevState,
                settings:{
                    ...prevState.settings,
                    WhenToPush: targetValue
                }
            }));
        }
    }

    /**
     * Add a new schedule to the Auto Scheduler list
     */
    handleAddSchedule(type) {
        if(type === "OPEN_CHARGES_IMPORT") {
            this.setState(prevState => ({
                ...prevState,
                pullingChargesSchedulerList: [...prevState.pullingChargesSchedulerList, this.getPullingSchedulerDefaultVal("OPEN_CHARGES_IMPORT")],
            }));
        } else if(type === "TENANTS_IMPORT") {
            this.setState(prevState => ({
                ...prevState,
                pullingTenantsSchedulerList: [...prevState.pullingTenantsSchedulerList, this.getPullingSchedulerDefaultVal("TENANTS_IMPORT")],
            }));
        } else if(type === "TRANSACTIONS_PUSH") {
            this.setState(prevState => ({
                ...prevState,
                pullingTransactionsSchedulerList: [...prevState.pullingTransactionsSchedulerList, this.getPullingSchedulerDefaultVal("TRANSACTIONS_PUSH")],
            }));
        } else if(type === "PROPERTIES_IMPORT") {
            this.setState(prevState => ({
                ...prevState,
                pullingPropertiesSchedulerList: [...prevState.pullingPropertiesSchedulerList, this.getPullingSchedulerDefaultVal("PROPERTIES_IMPORT")],
            }));
        }
        else if(type === "ENTRATA_TRANSACTIONS_EXPORT") {
            this.setState(prevState => ({
                ...prevState,
                entrataTransExportSchedulerList: [...prevState.entrataTransExportSchedulerList, this.getPullingSchedulerDefaultVal("ENTRATA_TRANSACTIONS_EXPORT")],
            }));
        }
        else if(type === "ENTRATA_TRANSACTIONS_RECONCILE") {
            this.setState(prevState => ({
                ...prevState,
                entrataTransReconcileSchedulerList: [...prevState.entrataTransReconcileSchedulerList, this.getPullingSchedulerDefaultVal("ENTRATA_TRANSACTIONS_RECONCILE")],
            }));
        }
    }

    /**
     * Remove the selected schedule
     */
    handleRemoveSchedule(key, type) {
        if(type === "OPEN_CHARGES_IMPORT") {
            const pullingChargesSchedulerFilteredList = this.state.pullingChargesSchedulerList.map((schedule,id)=>{
                if(id === key) {
                    schedule.active = !schedule.active;
                }
                return schedule
            });
    
            this.setState(prevState => ({
                ...prevState,
                pullingChargesSchedulerList: pullingChargesSchedulerFilteredList,
            }));
        } else if(type === "TENANTS_IMPORT") {
            const pullingTenantsSchedulerFilteredList = this.state.pullingTenantsSchedulerList.map((schedule,id)=>{
                if(id === key) {
                    schedule.active = !schedule.active;
                }
                return schedule
            });
    
            this.setState(prevState => ({
                ...prevState,
                pullingTenantsSchedulerList: pullingTenantsSchedulerFilteredList,
            }));
        } else if(type === "TRANSACTIONS_PUSH") {
            const pullingTransactionsSchedulerFilteredList = this.state.pullingTransactionsSchedulerList.map((schedule,id)=>{
                if(id === key) {
                    schedule.active = !schedule.active;
                }
                return schedule
            });
    
            this.setState(prevState => ({
                ...prevState,
                pullingTransactionsSchedulerList: pullingTransactionsSchedulerFilteredList,
            }));
        } else if(type === "PROPERTIES_IMPORT") {
            const pullingPropertiesSchedulerFilteredList = this.state.pullingPropertiesSchedulerList.map((schedule,id)=>{
                if(id === key) {
                    schedule.active = !schedule.active;
                }
                return schedule
            });

            this.setState(prevState => ({
                ...prevState,
                pullingPropertiesSchedulerList: pullingPropertiesSchedulerFilteredList,
            }));
        }
        else if(type === "ENTRATA_TRANSACTIONS_EXPORT") {
            const entrataTransExportFilteredList = this.state.entrataTransExportSchedulerList.map((schedule,id)=>{
                if(id === key) {
                    schedule.active = !schedule.active;
                }
                return schedule
            });

            this.setState(prevState => ({
                ...prevState,
                entrataTransExportSchedulerList: entrataTransExportFilteredList,
            }));
        }
        else if(type === "ENTRATA_TRANSACTIONS_RECONCILE") {
            const entrataTransReconcileFilteredList = this.state.entrataTransReconcileSchedulerList.map((schedule,id)=>{
                if(id === key) {
                    schedule.active = !schedule.active;
                }
                return schedule
            });

            this.setState(prevState => ({
                ...prevState,
                entrataTransReconcileSchedulerList: entrataTransReconcileFilteredList,
            }));
        }
    }
 
    /**
     * Get a default version of pulling charge schedule
     */
    getPullingSchedulerDefaultVal(type) {
        return {
            pullingOpenDate: null,
            pullingOpenTime: "00:00",
            pullingCloseDate: null,
            active: true,
            schedulerName: type ?? null,
        }
    }

    /**
     * Get the list of all pulling charge schedulers
     */
    getPullingSchedulerList(integrationId) {
        axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${integrationId}/schedulers`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            const pullingSchedulers = response.data;

            const pullingChargesSchedulerListArr = [];
            const pullingTenantsSchedulerListArr = [];
            const pullingTransactionsSchedulerListArr = [];
            const pullingPropertiesSchedulerListArr = [];
            const entrataTransExportSchedulerListArr = [];
            const entrataTransReconcileSchedulerListArr = [];

            pullingSchedulers.forEach(pullingSchedule => {
                const cronArr = pullingSchedule.cronExpression?.split(" ");
                const dateRangeArr = cronArr[3].split("-");
                const pullingOpenDate= dateRangeArr[0];
                const pullingOpenTimeArr= `${cronArr[2]}:${cronArr[1]}`;
                const pullingCloseDate= dateRangeArr[1];

                const data = {
                    pullingId: pullingSchedule.id,
                    pullingOpenDate: pullingOpenDate,
                    pullingOpenTime: pullingOpenTimeArr,
                    pullingCloseDate: pullingCloseDate,
                    active: pullingSchedule.active,
                    schedulerName: pullingSchedule?.schedulerName,
                }

                if(pullingSchedule?.schedulerName === "OPEN_CHARGES_IMPORT") {
                    pullingChargesSchedulerListArr.push(data);
                } else if(pullingSchedule?.schedulerName === "TENANTS_IMPORT") {
                    pullingTenantsSchedulerListArr.push(data);
                } else if(pullingSchedule?.schedulerName === "TRANSACTIONS_PUSH") {
                    pullingTransactionsSchedulerListArr.push(data);
                }else if(pullingSchedule?.schedulerName === "PROPERTIES_IMPORT") {
                    pullingPropertiesSchedulerListArr.push(data);
                }
                else if(pullingSchedule?.schedulerName === "ENTRATA_TRANSACTIONS_EXPORT") {
                    entrataTransExportSchedulerListArr.push(data);
                }
                else if(pullingSchedule?.schedulerName === "ENTRATA_TRANSACTIONS_RECONCILE") {
                    entrataTransReconcileSchedulerListArr.push(data);
                }
            });

            if(!pullingChargesSchedulerListArr.length) {
                pullingChargesSchedulerListArr.push(this.getPullingSchedulerDefaultVal("OPEN_CHARGES_IMPORT"));
            } 
            if(!pullingTenantsSchedulerListArr.length) {
                pullingTenantsSchedulerListArr.push(this.getPullingSchedulerDefaultVal("TENANTS_IMPORT"));
            }
            if(!pullingTransactionsSchedulerListArr.length) {
                pullingTransactionsSchedulerListArr.push(this.getPullingSchedulerDefaultVal("TRANSACTIONS_PUSH"));
            }
            if(!pullingPropertiesSchedulerListArr.length) {
                pullingPropertiesSchedulerListArr.push(this.getPullingSchedulerDefaultVal("PROPERTIES_IMPORT"));
            }
            if(!entrataTransExportSchedulerListArr.length) {
                entrataTransExportSchedulerListArr.push(this.getPullingSchedulerDefaultVal("ENTRATA_TRANSACTIONS_EXPORT"));
            }
            if(!entrataTransReconcileSchedulerListArr.length) {
                entrataTransReconcileSchedulerListArr.push(this.getPullingSchedulerDefaultVal("ENTRATA_TRANSACTIONS_RECONCILE"));
            }
            this.setState(prevState => ({
                ...prevState, 
                pullingSchedulerListAllData: pullingSchedulers,
                pullingChargesSchedulerList: pullingChargesSchedulerListArr,
                pullingTenantsSchedulerList: pullingTenantsSchedulerListArr,
                pullingTransactionsSchedulerList: pullingTransactionsSchedulerListArr,
                pullingPropertiesSchedulerList: pullingPropertiesSchedulerListArr,
                entrataTransExportSchedulerList: entrataTransExportSchedulerListArr,
                entrataTransReconcileSchedulerList: entrataTransReconcileSchedulerListArr
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Save pulling charge schedule
     */
    savePullingSchedule(integrationId) {
        const promises = [];
        let pullingScheduleWarning = false;
        const pullingSchedulerListAllData = this.state.pullingSchedulerListAllData;

        const pullingSchedulerList = [...this.state.pullingChargesSchedulerList, 
            ...this.state.pullingTenantsSchedulerList,
            ...this.state.pullingTransactionsSchedulerList, 
            ...this.state.pullingPropertiesSchedulerList,
            ...this.state.entrataTransExportSchedulerList,
            ...this.state.entrataTransReconcileSchedulerList]
        
        pullingSchedulerList.forEach(pullingSchedule => {
            const pullingOpenDate= pullingSchedule.pullingOpenDate;
            const pullingCloseDate= pullingSchedule.pullingCloseDate
            const pullingOpenTime= pullingSchedule.pullingOpenTime;

            if(!pullingOpenDate || !pullingOpenTime || !pullingCloseDate || pullingOpenTime === "No date selected" || pullingCloseDate === "No date selected" || (parseInt(pullingOpenDate) > parseInt(pullingCloseDate))) {
                if(!pullingScheduleWarning) { 
                    this.setState(prevState => ({
                        ...prevState,
                        pullingScheduleWarning: true,
                    }));
                    // clear warning
                    setTimeout(() => {
                        this.setState(prevState => ({
                            ...prevState,
                            pullingScheduleWarning: false,
                        }));
                        this.getPullingSchedulerList(integrationId);
                    }, 6000);
                    pullingScheduleWarning = true;
                }
                return;
            }
            
            const pullingOpenTimeArr= pullingOpenTime?.split(":");

            const hour = pullingOpenTimeArr[0] ?? "";
            const min = pullingOpenTimeArr[1] ?? "";

            const cronExpression = `00 ${min} ${hour} ${pullingOpenDate}-${pullingCloseDate} * *`; 
            const params = {
                integrationId: integrationId,
                schedulerName: pullingSchedule.schedulerName,
                cronExpression: cronExpression,
                active: pullingSchedule.active ?? true,
            };
            if(pullingSchedule.pullingId) {
                if(this.checkIfPullingScheduleToBeUpdated(pullingSchedulerListAllData, params)) {
                    promises.push(this.updatePullingSchedule(params, pullingSchedule.pullingId));
                } else {
                    promises.push(Promise.resolve());
                }
            } else if(pullingSchedule.active) {
                promises.push(this.createPullingSchedule(params));
            }
        });
        
        Promise.allSettled(promises)
            .then(() => this.getPullingSchedulerList(integrationId));
    }

    /**
     * Create a new pulling schedule
     */
    createPullingSchedule(params) {
        return axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/scheduler/create`, params, {
            headers: this.generateRequestHeaders()
        })
    }

    /**
     * Update an existing pulling schedule
     */
    updatePullingSchedule(params, schedulerId) {
        return axios.patch(`${constants.REACT_APP_HOST_INTEGRATION_URL}/scheduler/${schedulerId}/update`, params, {
            headers: this.generateRequestHeaders()
        })
    }

    /**
     * Find out if the pulling charge schedule needs to be updated
     */
    checkIfPullingScheduleToBeUpdated(pullingSchedulerListAllData, params) {
        let update = true;
        if(pullingSchedulerListAllData) {
            pullingSchedulerListAllData.forEach(pullingSchedule => {
                if(pullingSchedule.cronExpression === params.cronExpression && pullingSchedule.active === params.active && pullingSchedule.schedulerName === params.schedulerName) {
                    update = false;
                }
            });
        } 
        return update;
    }

    /**
     * Handle change to push reversal checkbox
     */
    handleChangePushReversal(type) {
        let pushReversals = []
        if(Array.isArray(this.state['pushReversals'])) {
            pushReversals = this.state['pushReversals'];
        }
        if(pushReversals?.includes(type)) {
            pushReversals.splice(pushReversals.indexOf(type), 1);
        } else {
            pushReversals.push(type);
        }

        this.setState(prevState => ({
            ...prevState,
            pushReversals: pushReversals,
        }));
    }

    /**
     * The push reversal flag is always not boolean and this function takes care of that
     */
    calculateBooleanPushReversal(type) {
        let pushReversals = []
        if(Array.isArray(this.state['pushReversals'])) {
            pushReversals = this.state['pushReversals'];
        }
        return pushReversals?.includes(type);
    }

    /**
     * Handle change to disable batch closer checkbox
     */
    handleChangeDisableBatchCloser(){
        this.setState(prevState =>({
            ...prevState,
            disableBatchCloserBool: !prevState.disableBatchCloserBool
        }));
    }

    /**
     * Handle change to posting batch period checkbox
     */
    handleChangePostingBatchPeriod(){
        this.setState(prevState =>({
            ...prevState,
            postingBatchPeriodBool: !prevState.postingBatchPeriodBool
        }));
    }

    /**
     * Handle change to pull paid charges checkbox
     */
    handleChangePullPaidCharges(){
        this.setState(prevState =>({
            ...prevState,
            pullPaidChargesBool: !prevState.pullPaidChargesBool
        }));
    }

    /**
     * Handle change to pull open charges checkbox
     */
    handleChangePullOpenCharges(){
        this.setState(prevState =>({
            ...prevState,
            pullOpenChargesBool: !prevState.pullOpenChargesBool
        }));
    }

    handleChangeCompressedConfirmation(){
        this.setState(prevState =>({
            ...prevState,
            compressedConfirmationIdBool: !prevState.compressedConfirmationIdBool
        }));
    }

    handleChangeCarryOverCharge(){
        this.setState(prevState =>({
            ...prevState,
            enableCarryOverCharges: !prevState.enableCarryOverCharges
        }));
    }

    /**
     * Handle change to pull only existing active lease
     */
    handleChangePullOnlyExistingActiveLease(){
        this.setState(prevState =>({
            ...prevState,
            pullOnlyExistingActiveLeaseBool: !prevState.pullOnlyExistingActiveLeaseBool
        }));
    }

    /**
     * Calls the API to fetch QB external authorization URL. 
     * On a successful retrieval of the authorization URL, the user will be redirected to the external URL.
     */
    handleQBAuthorization() {
        const integrationId = this.state.company.integrationId;
        this.startSpinner();
        axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${integrationId}/oauth2_authorize`, {
            headers: this.generateRequestHeaders()
        }).then((response)=>{
            this.stopSpinner();
            window.open(response.data, '_blank');
        }).catch((error)=>{
            window.scrollTo(0, 0);
            this.handleValidation(error);
        })
    }

    /**
     * Handle change to pull mri co residents checkbox
     */
    handleChangePullCoResidents(){

        this.setState(prevState =>({
            ...prevState,
            pullCoResidentsBool: !prevState.pullCoResidentsBool
        }));
    }

    /**
     * Handle change to pull mri co residents checkbox
     */
    handleChangePullNewResidents(){

        this.setState(prevState =>({
            ...prevState,
            pullNewResidentsBool: !prevState.pullNewResidentsBool
        }));
    }

    /**
     * Handle change to mark reversal pushing as failed checkbox
     */
    handleChangeMarkReversalsPushingFailed(){
        this.setState(prevState =>({
            ...prevState,
            markReversalsPushingFailedBool: !prevState.markReversalsPushingFailedBool
        }));
    }

    /**
     * The mark reversals pushing failed flag is always not boolean and this function takes care of that
     */
    calculateBooleanMarkReversalsPushingFailed() {
        return this.state.markReversalsPushingFailedBool;
    }

    /**
     * Handle change to enable push security deposit checkbox
     */
    handleChangePushSecurityDeposit(){

        this.setState(prevState =>({
            ...prevState,
            pushSecurityDepositsBool: !prevState.pushSecurityDepositsBool
        }));
    }

    /**
     * Handle change to push with charge codes checkbox
     */
    handleChangePushWithChargeCodes() {
        let pushWithChargeCodes = []
        if(Array.isArray(this.state['pushWithChargeCodes'])) {
            pushWithChargeCodes = this.state['pushWithChargeCodes'];
        }
        if(pushWithChargeCodes?.includes('PAYMENT')) {
            pushWithChargeCodes.splice(pushWithChargeCodes.indexOf('PAYMENT'), 1);
        } else {
            pushWithChargeCodes.push('PAYMENT');
        }
        if(pushWithChargeCodes?.includes('CHARGEBACK')) {
            pushWithChargeCodes.splice(pushWithChargeCodes.indexOf('CHARGEBACK'), 1);
        } else {
            pushWithChargeCodes.push('CHARGEBACK');
        }
        if(pushWithChargeCodes?.includes('RETURN')) {
            pushWithChargeCodes.splice(pushWithChargeCodes.indexOf('RETURN'), 1);
        } else {
            pushWithChargeCodes.push('RETURN');
        }
        if(pushWithChargeCodes?.includes('NSF')) {
            pushWithChargeCodes.splice(pushWithChargeCodes.indexOf('NSF'), 1);
        } else {
            pushWithChargeCodes.push('NSF');
        }
        if(pushWithChargeCodes?.includes('REFUND')) {
            pushWithChargeCodes.splice(pushWithChargeCodes.indexOf('REFUND'), 1);
        } else {
            pushWithChargeCodes.push('REFUND');
        }

        this.setState(prevState => ({
            ...prevState,
            pushWithChargeCodes: pushWithChargeCodes,
        }));
    }

    /**
     * The push with charge codes flag is always not boolean and this function takes care of that
     */
    calculateBooleanPushWithChargeCodes() {
        let pushWithChargeCodes = []
        if(Array.isArray(this.state['pushWithChargeCodes'])) {
            pushWithChargeCodes = this.state['pushWithChargeCodes'];
        }
        return pushWithChargeCodes?.includes('PAYMENT');
    }

    /**
     * Render the component.
     *
     * @returns {*} - The company integrations component.
     */
    render() {
        
        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                {
                    this.state.company.integrationId &&
                        this.state.integration?.["integrationType"] === "QUICKBOOKS" &&
                        this.state.integration?.settings?.AUTHORIZED_EXTERNAL === "false" && (
                            <div className="card card-warning border-warning">
                                <div className="card-body">
                                    <h4 className="card-title">
                                        This Authorization for this integration is pending.
                                    </h4>
                                    <p className="card-text">
                                        Please click on the Authorize button at the bottom of this page to start the Authorization process.
                                        Please refresh the page to get the latest Authorization status.
                                    </p>
                                </div>
                            </div>
                        )
                }

                <form onSubmit={this.saveIntegration}>

                    <div className="card">

                        <div className="card-header">
                            Integrations
                        </div>

                        {this.state.integration == null &&
                        <div className="card-body text-center">

                            <p className="pt-3 pb-1">This company does not currently have an integration set up.</p>

                            <div className="btn btn-primary btn-lg mb-4" onClick={() => this.initIntegration()}>
                                Add an Integration
                            </div>

                        </div>
                        }

                        {this.state.integration != null &&
                        <div className="card-body">

                            <FieldSelect id="integrationType" label="Integration Type" model="integration" disabled={this.state.integration.createDate} parent={this} value={this.state.integration['integrationType']}>
                                <option value="">Select an integration type...</option>
                                <option value="MRI">MRI</option>
                                <option value="YARDI">Yardi</option>
                                <option value="RESMAN">ResMan</option>
                                <option value="NETSUITE">Netsuite</option>
                                <option value="ENTRATA">Entrata</option>
                                <option value="RENTMANAGER">RentManager</option>
                                <option value="SAP">SAP</option>
                                <option value="PROPERTYWARE">Propertyware</option>
                                <option value="MRI_COMMERCIAL">MRI Commercial</option>
                                <option value="QUICKBOOKS">Quickbooks</option>
                                <option value="APPFOLIO">Appfolio</option>
                                <option value="CUSTOM">Custom</option>
                            </FieldSelect>

                            <FieldTimezone id="timeZone" label="Timezone" model="integration" parent={this} value={this.state.integration['timeZone']} />

                            <FieldCheckbox id="integratedBalance" label="Integrated Balance" 
                                           fieldLabel="Utilize the balance due from the integrated system for payments" 
                                           help="If checked, Letus will NOT automatically post monthly charges. Instead, a balance must be imported manually from the integrated system every month." 
                                           model="integration" parent={this} disabled={this.state.disabledIntegratedBalance || this.state.integration['integrationType'] === 'SAP' || this.state.integration['integrationType'] === 'APPFOLIO'}
                                           value={this.state.integration['integratedBalance']} />

                            <FieldCheckbox id="PullOpenCharges" label="Pull Recurring Charges" fieldLabel="Pulls only recurring Charges from the integrated system for payments" parent={this} value={!this.state.pullOpenChargesBool} handleChange={this.handleChangePullOpenCharges}/>

                            {(this.state.integration['integrationType'] === 'MRI' || 
                              this.state.integration['integrationType'] === 'MRI_COMMERCIAL') &&
                            <div className="">

                                <FieldText id="ClientId" label="Client ID" model="settings" parent={this} value={this.state.settings['ClientId']} />

                                <FieldText id="Database" label="Database" model="settings" parent={this} value={this.state.settings['Database']} />

                                <IntegrationForm 
                                    parent={this} 
                                    settings={this.state.settings} 
                                    batchRollverExpression={this.state.batchRollverExpression}
                                    batchRollverTime={this.state.batchRollverTime}
                                    toggleBatchRollverInpField={this.toggleBatchRollverInpField}
                                    calculateBooleanPushReversal={this.calculateBooleanPushReversal}
                                    handleChangePushReversal={this.handleChangePushReversal}
                                    handleChangeWhenToPushField={this.handleChangeWhenToPushField}
                                    calculateBooleanMarkReversalsPushingFailed={this.calculateBooleanMarkReversalsPushingFailed}
                                    handleChangeMarkReversalsPushingFailed={this.handleChangeMarkReversalsPushingFailed}
                                />

                                <FieldCheckbox id="PullCoResidents" label="Enable Co-residents" fieldLabel="Pull Roommate information from Integration side" parent={this} value={this.state.pullCoResidentsBool} handleChange={this.handleChangePullCoResidents}/>
                                <FieldCheckbox id="PullNewResidents" label="Enable New-residents" fieldLabel="Pull New residents from Integration side" parent={this} value={this.state.pullNewResidentsBool} handleChange={this.handleChangePullNewResidents}/>
                                <FieldCheckbox id="PullOnlyExistingActiveLease" label="Pull Only Existing Active Lease" fieldLabel="Pull Only Existing Active Lease" parent={this} value={this.state.pullOnlyExistingActiveLeaseBool} handleChange={this.handleChangePullOnlyExistingActiveLease}/>
                                <FieldCheckbox id="PushSecurityDeposits" label="Enable Push Security Deposit" fieldLabel="Enable Push Security Deposit" parent={this} value={this.state.pushSecurityDepositsBool} handleChange={this.handleChangePushSecurityDeposit}/>
                                <FieldText id="securityDepositCode" label="Security Deposit Code" parent={this} value={this.state.securityDepositCode} disabled={!this.state.pushSecurityDepositsBool}/>
                                <FieldCheckbox id="PullPaidCharges" label="Pull Paid Charges" fieldLabel="Pull Paid Charges" parent={this} value={this.state.pullPaidChargesBool} handleChange={this.handleChangePullPaidCharges}/>
                                <FieldCheckbox id="PushWithChargeCodes" label="Push With Charge Codes" fieldLabel="Enable Push With Charge Codes" parent={this} value={this.calculateBooleanPushWithChargeCodes()} handleChange={this.handleChangePushWithChargeCodes}/>

                                <FieldRadio id="WhenToPush" label="When to Push transactions to MRI" isIntegrationSettingsWhenToPush
                                            fieldColumns="9" widthAlign="20%" parent={this} required
                                            value={this.state.settings['WhenToPush']} handleChange={(event)=>this.handleChangeWhenToPushField(event)}/>

                                <FieldCheckbox 
                                    id="compressedConfirmationId" 
                                    label="Confirmation-ID must be pushed" 
                                    fieldLabel="Encoded/Compressed" 
                                    parent={this} 
                                    value={this.state.compressedConfirmationIdBool}
                                    handleChange={this.handleChangeCompressedConfirmation}/>

                                <FieldCheckbox id="DisableBatchCloser" label="Disable Batch Rollover" fieldLabel="Disable Batch Rollover" parent={this} value={this.state.disableBatchCloserBool}  handleChange={this.handleChangeDisableBatchCloser}/>

                                { this.state.batchRollverTextField
                                    ?
                                    <FieldText id="batchRollverExpression" disabled={this.state.settings['WhenToPush'] === 'SETTLED' || this.state.disableBatchCloserBool} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverExpression} help="Above value will be applied in the selected timezone. You can use https://crontab.guru to create a cron expression. The expression has to be 6 specifiers long: secs  mins  hours  day-of-month  month  day-of-week." AddnlBtn1Text="Select time instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField}/>
                                    :
                                    <FieldTime required={true} id="batchRollverTime" disabled={this.state.settings['WhenToPush'] === 'SETTLED' || this.state.disableBatchCloserBool} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverTime} preText="Daily" help="Above value will be applied in the selected timezone." AddnlBtn1Text="Enter a cron expression instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField} placeholder="23:59" pattern="[0-2]?[0-9]:[0-6]?[0-9]" onInvalid={(e)=>{e.target.setCustomValidity("Please enter a valid time. Time format should be '24:00'.")}} onInput={(e)=>{e.target.setCustomValidity('')}}/>
                                }

                                <FieldCheckbox id="PostingBatchPeriod" disabled={this.state.settings['WhenToPush'] === 'SETTLED' || this.state.disableBatchCloserBool} label="Post Batch Period" fieldLabel="Post Batch Period" parent={this} value={this.state.postingBatchPeriodBool}  handleChange={this.handleChangePostingBatchPeriod}/>

                                <FieldCheckbox id="active" label="Blackout Period" fieldLabel="Enable Blackout Period" model="blackoutPeriod" parent={this} value={this.state.blackoutPeriod['active']} />

                                <FieldDateTime id="openDate" label="" parent={this} model="blackoutPeriod" value={this.state.blackoutPeriod['openDate']} preText="From" disabled={!this.state.blackoutPeriod['active']} before = {new Date()} containerClass="mb-2"/>

                                <FieldDateTime id="closeDate" label="" parent={this} model="blackoutPeriod" value={this.state.blackoutPeriod['closeDate']} preText="To" disabled={!this.state.blackoutPeriod['active']} before = {new Date()} infoCode="blackoutperiod" containerClass="mb-3"/>
                            </div>
                            }

                            {this.state.integration['integrationType'] === 'YARDI' &&
                            <div className="">

                                <IntegrationForm 
                                    parent={this} 
                                    settings={this.state.settings} 
                                    batchRollverExpression={this.state.batchRollverExpression}
                                    batchRollverTime={this.state.batchRollverTime}
                                    hasDatabase={true}
                                    userNameFormat='Username'
                                    toggleBatchRollverInpField={this.toggleBatchRollverInpField}
                                    calculateBooleanPushReversal={this.calculateBooleanPushReversal}
                                    handleChangePushReversal={this.handleChangePushReversal}
                                    handleChangeWhenToPushField={this.handleChangeWhenToPushField}
                                    calculateBooleanMarkReversalsPushingFailed={this.calculateBooleanMarkReversalsPushingFailed}
                                    handleChangeMarkReversalsPushingFailed={this.handleChangeMarkReversalsPushingFailed}
                                />

                                <FieldCheckbox id="PullPaidCharges" label="Pull Paid Charges" fieldLabel="Pull Paid Charges" parent={this} value={this.state.pullPaidChargesBool}
                                handleChange={this.handleChangePullPaidCharges}/>

                                <FieldRadio id="WhenToPush" label="When to Push transactions to YARDI" isIntegrationSettingsWhenToPush
                                            fieldColumns="9" widthAlign="20%" parent={this} required
                                            value={this.state.settings['WhenToPush']} handleChange={(event)=>this.handleChangeWhenToPushField(event)}/>

                                <FieldCheckbox id="compressedConfirmationId" label="Confirmation-ID must be pushed" fieldLabel="Encoded/Compressed" parent={this} value={this.state.compressedConfirmationIdBool}
                                    handleChange={this.handleChangeCompressedConfirmation}/>

                                <FieldText id="ServerName" label="Server Name" model="settings" parent={this} value={this.state.settings['ServerName']} />

                                <FieldText id="Interface" label="Interface" model="settings" parent={this} value={this.state.settings['Interface']} />

                                <FieldText id="Platform" label="Platform" model="settings" parent={this} value={this.state.settings['Platform']} />

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'ENTRATA' &&
                                <div className="">

                                    <IntegrationForm
                                        parent={this}
                                        settings={this.state.settings}
                                        batchRollverExpression={this.state.batchRollverExpression}
                                        batchRollverTime={this.state.batchRollverTime}
                                        userNameFormat='Username'
                                        toggleBatchRollverInpField={this.toggleBatchRollverInpField}
                                        calculateBooleanPushReversal={this.calculateBooleanPushReversal}
                                        handleChangePushReversal={this.handleChangePushReversal}
                                        handleChangeWhenToPushField={this.handleChangeWhenToPushField}
                                        calculateBooleanMarkReversalsPushingFailed={this.calculateBooleanMarkReversalsPushingFailed}
                                        handleChangeMarkReversalsPushingFailed={this.handleChangeMarkReversalsPushingFailed}
                                    />

                                    <FieldCheckbox id="PullPaidCharges" label="Pull Paid Charges" fieldLabel="Pull Paid Charges" parent={this} value={this.state.pullPaidChargesBool}
                                                   handleChange={this.handleChangePullPaidCharges}/>

                                    <FieldRadio id="WhenToPush" label="When to Push transactions to Entrata" isIntegrationSettingsWhenToPush
                                                fieldColumns="9" widthAlign="20%" parent={this} required
                                                value={this.state.settings['WhenToPush']} handleChange={(event)=>this.handleChangeWhenToPushField(event)}/>

                                    <FieldCheckbox id="compressedConfirmationId" label="Confirmation-ID must be pushed" fieldLabel="Encoded/Compressed" parent={this} value={this.state.compressedConfirmationIdBool}
                                                   handleChange={this.handleChangeCompressedConfirmation}/>

                                    {/* Entrata Transaction Export Scheduler */}
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                            Transaction Export Scheduler
                                        </label>
                                        <div className="col-sm-9 col-form-label col-form-label-sm">
                                            Schedule transactions to be automatically exported to CSV file(s) for import into Entrata system.
                                        </div>
                                    </div>
                                    <div className='row col-form-label-sm'>
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-3">From</div>
                                        <div className="col-sm-3">To</div>
                                        <div className="col-sm-3">Time</div>
                                    </div>
                                    { this.state.entrataTransExportSchedulerList?.map((entrataTransExportSchedulerList, key)=>{
                                        return (
                                            <div className="mt-2" key={"scheduler"+key} >
                                                <FieldDateTimeChargesScheduler
                                                    key={"scheduler"+key}
                                                    idOpenDate="pullingOpenDate"
                                                    idOpenTime="pullingOpenTime"
                                                    idCloseDate="pullingCloseDate"
                                                    label="" parent={this}
                                                    model="entrataTransExportSchedulerList"
                                                    arrayIndex={key}
                                                    valueOpenDate={entrataTransExportSchedulerList.pullingOpenDate}
                                                    valueOpenTime={entrataTransExportSchedulerList.pullingOpenTime}
                                                    valueCloseDate={entrataTransExportSchedulerList.pullingCloseDate}
                                                    active={entrataTransExportSchedulerList.active}
                                                    handleRemove={()=>this.handleRemoveSchedule(key, "ENTRATA_TRANSACTIONS_EXPORT")}
                                                    help=""
                                                    containerClass="mb-2"/>
                                            </div>
                                        );
                                    })}
                                    <div className='row col-form-label-sm form-group'>
                                        <div className="col-sm-5"></div>
                                        <div className="col-sm-7">
                                            <div className="btn btn-outline-primary btn-sm mt-2" onClick={() => {this.handleAddSchedule("ENTRATA_TRANSACTIONS_EXPORT")}}>
                                                <FontAwesomeIcon icon={["fas", "plus"]} className="fa-fw" />{" "}
                                                Add Schedule
                                            </div>
                                        </div>
                                    </div>
                                    {/* Entrata Transaction Reconcile Scheduler */}
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                            Transaction Reconciliation Scheduler
                                        </label>
                                        <div className="col-sm-9 col-form-label col-form-label-sm">
                                            Schedule transactions to be automatically reconciled with Entrata system.
                                        </div>
                                    </div>
                                    <div className='row col-form-label-sm'>
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-3">From</div>
                                        <div className="col-sm-3">To</div>
                                        <div className="col-sm-3">Time</div>
                                    </div>
                                    { this.state.entrataTransReconcileSchedulerList?.map((entrataTransReconcileSchedulerList, key)=>{
                                        return (
                                            <div className="mt-2" key={"scheduler"+key} >
                                                <FieldDateTimeChargesScheduler
                                                    key={"scheduler"+key}
                                                    idOpenDate="pullingOpenDate"
                                                    idOpenTime="pullingOpenTime"
                                                    idCloseDate="pullingCloseDate"
                                                    label="" parent={this}
                                                    model="entrataTransReconcileSchedulerList"
                                                    arrayIndex={key}
                                                    valueOpenDate={entrataTransReconcileSchedulerList.pullingOpenDate}
                                                    valueOpenTime={entrataTransReconcileSchedulerList.pullingOpenTime}
                                                    valueCloseDate={entrataTransReconcileSchedulerList.pullingCloseDate}
                                                    active={entrataTransReconcileSchedulerList.active}
                                                    handleRemove={()=>this.handleRemoveSchedule(key, "ENTRATA_TRANSACTIONS_RECONCILE")}
                                                    help=""
                                                    containerClass="mb-2"/>
                                            </div>
                                        );
                                    })}
                                </div>
                            }

                            {this.state.integration['integrationType'] === 'RESMAN' &&
                            <div className="">

                                <FieldText id="AccountId" label="Account ID" model="settings" parent={this} value={this.state.settings['AccountId']} />

                                <FieldCheckbox id="pushReversalsRefund" label="Push Refunds" model="" fieldLabel="Push refunds to Integrated system" parent={this} value={this.calculateBooleanPushReversal('REFUND')} 
                                handleChange={()=>{this.handleChangePushReversal('REFUND')}}/>

                                <FieldCheckbox id="pushReversalsReturn" label="Push Returns" model="" fieldLabel="Push returns to Integrated system" parent={this} value={this.calculateBooleanPushReversal('RETURN')} 
                                handleChange={()=>{this.handleChangePushReversal('RETURN')}}/>

                                <FieldCheckbox id="pushReversalsChargeback" label="Push ChargeBacks" model="" fieldLabel="Push charge backs to Integrated system" parent={this} value={this.calculateBooleanPushReversal('CHARGEBACK')} 
                                handleChange={()=>{this.handleChangePushReversal('CHARGEBACK')}}/>

                                <FieldCheckbox id="pushReversalsNsf" label="Push NSF" model="" fieldLabel="Push NSF to Integrated system" parent={this} value={this.calculateBooleanPushReversal('NSF')} 
                                handleChange={()=>{this.handleChangePushReversal('NSF')}}/>

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'NETSUITE' &&
                            <div className="">

                                <FieldText id="AccountId" label="Account ID" model="settings" parent={this} value={this.state.settings['AccountId']} />

                                <FieldText id="Email" label="Email" model="settings" parent={this} value={this.state.settings['Email']} />

                                <FieldText id="Categories" label="Categories" model="settings" parent={this} value={this.state.settings['Categories']} />

                                <FieldText id="CityFieldId" label="City Field Id" model="settings" parent={this} value={this.state.settings['CityFieldId']} />

                                <FieldText id="RoleId" label="Role Id" model="settings" parent={this} value={this.state.settings['RoleId']} />

                                <FieldText id="StateFieldId" label="State Field Id" model="settings" parent={this} value={this.state.settings['StateFieldId']} />

                                <FieldText id="PropertyRecordId" label="Property Record Id" model="settings" parent={this} value={this.state.settings['PropertyRecordId']} />

                                <FieldText id="UndepfundsSelect" label="Undepfunds Select" model="settings" parent={this} value={this.state.settings['UndepfundsSelect']} />

                                <FieldText id="PaymentPropertyFieldId" label="Payment Property Field Id" model="settings" parent={this} value={this.state.settings['PaymentPropertyFieldId']} />

                                <FieldText id="StreetAddressFieldId" label="Street Address Field Id" model="settings" parent={this} value={this.state.settings['StreetAddressFieldId']} />

                                <FieldText id="CustomerPropertyFieldId" label="Customer Property Field Id" model="settings" parent={this} value={this.state.settings['CustomerPropertyFieldId']} />

                                <FieldText id="LocationFieldId" label="Location Field Id" model="settings" parent={this} value={this.state.settings['LocationFieldId']} />

                                <FieldText id="DepartmentId" label="Department Id" model="settings" parent={this} value={this.state.settings['DepartmentId']} />

                                <FieldText id="ApplicationId" label="Application Id" model="settings" parent={this} value={this.state.settings['ApplicationId']} />

                                <FieldText id="Password" label="Password" model="settings" type="password" parent={this} value={this.state.settings['Password']} />

                                <FieldText id="CADId" label="CAD Id" model="settings" parent={this} value={this.state.settings['CADId']} />

                                <FieldText id="USDId" label="USD Id" model="settings" parent={this} value={this.state.settings['USDId']} />

                                <FieldCheckbox id="pushReversalsRefund" label="Push Refunds" model="" fieldLabel="Push refunds to Integrated system" parent={this} value={this.calculateBooleanPushReversal('REFUND')}
                                               handleChange={()=>{this.handleChangePushReversal('REFUND')}}/>

                                <FieldCheckbox id="pushReversalsReturn" label="Push Returns" model="" fieldLabel="Push returns to Integrated system" parent={this} value={this.calculateBooleanPushReversal('RETURN')}
                                               handleChange={()=>{this.handleChangePushReversal('RETURN')}}/>

                                <FieldCheckbox id="pushReversalsChargeback" label="Push ChargeBacks" model="" fieldLabel="Push charge backs to Integrated system" parent={this} value={this.calculateBooleanPushReversal('CHARGEBACK')}
                                               handleChange={()=>{this.handleChangePushReversal('CHARGEBACK')}}/>

                                <FieldCheckbox id="pushReversalsNsf" label="Push NSF" model="" fieldLabel="Push NSF to Integrated system" parent={this} value={this.calculateBooleanPushReversal('NSF')}
                                               handleChange={()=>{this.handleChangePushReversal('NSF')}}/>

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'RENTMANAGER' &&
                                <div className="">

                                    <IntegrationForm
                                        parent={this}
                                        settings={this.state.settings}
                                        batchRollverExpression={this.state.batchRollverExpression}
                                        batchRollverTime={this.state.batchRollverTime}
                                        toggleBatchRollverInpField={this.toggleBatchRollverInpField}
                                        calculateBooleanPushReversal={this.calculateBooleanPushReversal}
                                        handleChangePushReversal={this.handleChangePushReversal}
                                        handleChangeWhenToPushField={this.handleChangeWhenToPushField}
                                        calculateBooleanMarkReversalsPushingFailed={this.calculateBooleanMarkReversalsPushingFailed}
                                        handleChangeMarkReversalsPushingFailed={this.handleChangeMarkReversalsPushingFailed}
                                    />

                                    <FieldText id="NsfFeeAmount" label="NSF Fee Amount" model="settings" parent={this} value={this.state.settings['NsfFeeAmount']} />
                                    <FieldText id="NsfFeeCommercialAmount" label="NSF Fee Commercial Amount" model="settings" parent={this} value={this.state.settings['NsfFeeCommercialAmount']} />
                                    <FieldCheckbox id="PullOnlyExistingActiveLease" label="Pull Only Existing Active Lease" fieldLabel="Pull Only Existing Active Lease" parent={this} value={this.state.pullOnlyExistingActiveLeaseBool} handleChange={this.handleChangePullOnlyExistingActiveLease}/>

                                </div>
                            }

                            {this.state.integration['integrationType'] === 'SAP' &&
                            <div className="">

                                <FieldText id="HostUrl" label="Host Url" model="settings" parent={this} value={this.state.settings['HostUrl']} />

                                <FieldSelect id="HostProtocol" label="Host Protocol" model="settings" parent={this} value={this.state.settings['HostProtocol']}>
                                    <option value="">Select an host protocol...</option>
                                    <option value="FTP">FTP</option>
                                    <option value="FTPS">FTPS</option>
                                    <option value="SFTP">SFTP</option>
                                </FieldSelect>

                                <FieldText id="HostUserName" label="Host UserName" model="settings" parent={this} value={this.state.settings['HostUserName']} />

                                <FieldText id="HostKey" label="Host Key" model="settings" parent={this} value={this.state.settings['HostKey']} />

                                <FieldText id="HostDirectory" label="Host Directory" model="settings" parent={this} value={this.state.settings['HostDirectory']} />

                                <FieldCheckbox id="pushReversalsRefund" label="Push Refunds" model="" fieldLabel="Push Refunds to Integrated system" parent={this} value={this.calculateBooleanPushReversal('REFUND')}
                                               handleChange={()=>{this.handleChangePushReversal('REFUND')}}/>

                                <FieldCheckbox id="pushReversalsReturn" label="Push Returns" model="" fieldLabel="Push returns to Integrated system" parent={this} value={this.calculateBooleanPushReversal('RETURN')}
                                               handleChange={()=>{this.handleChangePushReversal('RETURN')}}/>

                                <FieldCheckbox id="pushReversalsChargeback" label="Push ChargeBacks" model="" fieldLabel="Push charge backs to Integrated system" parent={this} value={this.calculateBooleanPushReversal('CHARGEBACK')}
                                               handleChange={()=>{this.handleChangePushReversal('CHARGEBACK')}}/>

                                <FieldCheckbox id="pushReversalsNsf" label="Push NSF" model="" fieldLabel="Push NSF to Integrated system" parent={this} value={this.calculateBooleanPushReversal('NSF')}
                                               handleChange={()=>{this.handleChangePushReversal('NSF')}}/>

                                <FieldRadio id="WhenToPush" label="When to Push transactions to MRI" isIntegrationSettingsWhenToPush
                                            fieldColumns="9" widthAlign="20%" parent={this} required
                                            value={this.state.settings['WhenToPush']} handleChange={(event)=>this.handleChangeWhenToPushField(event)}/>

                                { this.state.batchRollverTextField
                                    ?
                                    <FieldText id="batchRollverExpression" disabled={this.state.settings['WhenToPush'] === 'SETTLED'} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverExpression} help="Above value will be applied in the selected timezone. You can use https://crontab.guru to create a cron expression. The expression has to be 6 specifiers long: secs  mins  hours  day-of-month  month  day-of-week." AddnlBtn1Text="Select time instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField}/>
                                    :
                                    <FieldTime required={true} id="batchRollverTime" disabled={this.state.settings['WhenToPush'] === 'SETTLED'} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverTime} preText="Daily" help="Above value will be applied in the selected timezone." AddnlBtn1Text="Enter a cron expression instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField} placeholder="23:59" pattern="[0-2]?[0-9]:[0-6]?[0-9]" onInvalid={(e)=>{e.target.setCustomValidity("Please enter a valid time. Time format should be '24:00'.")}} onInput={(e)=>{e.target.setCustomValidity('')}}/>
                                }

                                <FieldCheckbox id="active" label="Blackout Period" fieldLabel="Enable Blackout Period" model="blackoutPeriod" parent={this} value={this.state.blackoutPeriod['active']} />

                                <FieldDateTime id="openDate" label="" parent={this} model="blackoutPeriod" value={this.state.blackoutPeriod['openDate']} preText="From" disabled={!this.state.blackoutPeriod['active']} before = {new Date()} containerClass="mb-2"/>

                                <FieldDateTime id="closeDate" label="" parent={this} model="blackoutPeriod" value={this.state.blackoutPeriod['closeDate']} preText="To" disabled={!this.state.blackoutPeriod['active']} before = {new Date()} infoCode="blackoutperiod" containerClass="mb-3"/>
                            </div>
                            }

                            {this.state.integration['integrationType'] === 'PROPERTYWARE' &&
                                <div className="">
                                    <IntegrationForm 
                                        parent={this} 
                                        settings={this.state.settings} 
                                        batchRollverExpression={this.state.batchRollverExpression}
                                        batchRollverTime={this.state.batchRollverTime}
                                        toggleBatchRollverInpField={this.toggleBatchRollverInpField}
                                        calculateBooleanPushReversal={this.calculateBooleanPushReversal}
                                        handleChangePushReversal={this.handleChangePushReversal}
                                        handleChangeWhenToPushField={this.handleChangeWhenToPushField}
                                        calculateBooleanMarkReversalsPushingFailed={this.calculateBooleanMarkReversalsPushingFailed}
                                        handleChangeMarkReversalsPushingFailed={this.handleChangeMarkReversalsPushingFailed}
                                    />

                                    <FieldRadio id="WhenToPush" label="When to Push transactions to Propertyware" isIntegrationSettingsWhenToPush
                                            fieldColumns="9" widthAlign="20%" parent={this} required
                                            value={this.state.settings['WhenToPush']} handleChange={(event)=>this.handleChangeWhenToPushField(event)}/>

                                    { this.state.batchRollverTextField
                                        ?
                                        <FieldText id="batchRollverExpression" disabled={this.state.settings['WhenToPush'] === 'SETTLED'} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverExpression} help="Above value will be applied in the selected timezone. You can use https://crontab.guru to create a cron expression. The expression has to be 6 specifiers long: secs  mins  hours  day-of-month  month  day-of-week." AddnlBtn1Text="Select time instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField}/>
                                        :
                                        <FieldTime required={true} id="batchRollverTime" disabled={this.state.settings['WhenToPush'] === 'SETTLED'} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverTime} preText="Daily" help="Above value will be applied in the selected timezone." AddnlBtn1Text="Enter a cron expression instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField} placeholder="23:59" pattern="[0-2]?[0-9]:[0-6]?[0-9]" onInvalid={(e)=>{e.target.setCustomValidity("Please enter a valid time. Time format should be '24:00'.")}} onInput={(e)=>{e.target.setCustomValidity('')}}/>
                                    }
                                </div>
                            }

                            {this.state.integration['integrationType'] === 'QUICKBOOKS' &&
                                <div className="">
                                    <FieldText id="CLIENT_ID" label="Client ID" model="settings" parent={this} value={this.state.settings['CLIENT_ID']} />
                                    
                                    <FieldText id="CLIENT_SECRET" label="Client Secret" model="settings" parent={this} value={this.state.settings['CLIENT_SECRET']} />
                                    
                                    <FieldText id="REDIRECT_URI" label="Redirect URI" model="settings" parent={this} value={this.state.settings['REDIRECT_URI']} />
                                    
                                    <FieldText id="BASE_URL" label="Base URL" model="settings" parent={this} value={this.state.settings['BASE_URL']} />
                                    
                                    <FieldText id="MINOR_VERSION" label="Minor Version" model="settings" parent={this} value={this.state.settings['MINOR_VERSION']} />

                                    <FieldText id="QBO_URL" label="QBO URL" model="settings" parent={this} value={this.state.settings['QBO_URL']} />

                                    <FieldText id="API_HOST" label="API HOST" model="settings" parent={this} value={this.state.settings['API_HOST']} />
                                </div>
                            }

                            {this.state.integration['integrationType'] === 'APPFOLIO' &&
                            <div className="">

                                <FieldText id="HostEmailAddress" label="Email Address" model="settings" parent={this} value={this.state.settings['HostEmailAddress']} />

                                <FieldCheckbox id="pushReversalsRefund" label="Push Refunds" model="" fieldLabel="Push Refunds to Integrated system" parent={this} value={this.calculateBooleanPushReversal('REFUND')}
                                    handleChange={()=>{this.handleChangePushReversal('REFUND')}}/>

                                <FieldCheckbox id="pushReversalsReturn" label="Push Returns" model="" fieldLabel="Push returns to Integrated system" parent={this} value={this.calculateBooleanPushReversal('RETURN')}
                                    handleChange={()=>{this.handleChangePushReversal('RETURN')}}/>

                                <FieldCheckbox id="pushReversalsChargeback" label="Push ChargeBacks" model="" fieldLabel="Push charge backs to Integrated system" parent={this} value={this.calculateBooleanPushReversal('CHARGEBACK')}
                                    handleChange={()=>{this.handleChangePushReversal('CHARGEBACK')}}/>

                                <FieldCheckbox id="pushReversalsNsf" label="Push NSF" model="" fieldLabel="Push NSF to Integrated system" parent={this} value={this.calculateBooleanPushReversal('NSF')}
                                    handleChange={()=>{this.handleChangePushReversal('NSF')}}/>

                                <FieldRadio id="WhenToPush" label="When to Push transactions to APPFOLIO" isIntegrationSettingsWhenToPush
                                    fieldColumns="9" widthAlign="20%" parent={this} required
                                    value={this.state.settings['WhenToPush']} handleChange={(event)=>this.handleChangeWhenToPushField(event)}/>

                                { this.state.batchRollverTextField
                                    ?
                                    <FieldText id="batchRollverExpression" disabled={this.state.settings['WhenToPush'] === 'SETTLED'} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverExpression} help="Above value will be applied in the selected timezone. You can use https://crontab.guru to create a cron expression. The expression has to be 6 specifiers long: secs  mins  hours  day-of-month  month  day-of-week." AddnlBtn1Text="Select time instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField}/>
                                    :
                                    <FieldTime required={true} id="batchRollverTime" disabled={this.state.settings['WhenToPush'] === 'SETTLED'} label="Batch Rollover Schedule" parent={this} value={this.state.batchRollverTime} preText="Daily" help="Above value will be applied in the selected timezone." AddnlBtn1Text="Enter a cron expression instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.toggleBatchRollverInpField} placeholder="23:59" pattern="[0-2]?[0-9]:[0-6]?[0-9]" onInvalid={(e)=>{e.target.setCustomValidity("Please enter a valid time. Time format should be '24:00'.")}} onInput={(e)=>{e.target.setCustomValidity('')}}/>
                                    }
                            </div>
                            }

                            {this.state.integration['integrationType'] === 'CUSTOM' &&
                            <div className="">

                                <FieldText id="pluginClass" label="Plugin Class" model="integration" parent={this} value={this.state.integration['pluginClass']} />

                                <FieldCheckbox id="pushReversalsRefund" label="Push Refunds" model="" fieldLabel="Push refunds to Integrated system" parent={this} value={this.calculateBooleanPushReversal('REFUND')} 
                                handleChange={()=>{this.handleChangePushReversal('REFUND')}}/>

                                <FieldCheckbox id="pushReversalsReturn" label="Push Returns" model="" fieldLabel="Push returns to Integrated system" parent={this} value={this.calculateBooleanPushReversal('RETURN')} 
                                handleChange={()=>{this.handleChangePushReversal('RETURN')}}/>

                                <FieldCheckbox id="pushReversalsChargeback" label="Push ChargeBacks" model="" fieldLabel="Push charge backs to Integrated system" parent={this} value={this.calculateBooleanPushReversal('CHARGEBACK')} 
                                handleChange={()=>{this.handleChangePushReversal('CHARGEBACK')}}/>

                                <FieldCheckbox id="pushReversalsNsf" label="Push NSF" model="" fieldLabel="Push NSF to Integrated system" parent={this} value={this.calculateBooleanPushReversal('NSF')} 
                                handleChange={()=>{this.handleChangePushReversal('NSF')}}/>

                                <div className="form-group row mb-2">

                                    <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="customSettings">
                                        Settings
                                    </label>

                                    <div className="col-sm-9">

                                        {this.state.customSettings.map((data, key) => {
                                            return (
                                                <div className="input-group input-group-sm mb-3" key={key}>

                                                    <input id={`custom-setting-key-${key}`} name={`custom-setting-key-${key}`} value={data.customSettingKey} placeholder="Name" className="form-control" onChange={this.handleChangeCustomIntegrationSettings} />

                                                    <input id={`custom-setting-value-${key}`} name={`custom-setting-value-${key}`} value={data.customSettingValue} placeholder="Value" className="form-control" onChange={this.handleChangeCustomIntegrationSettings} />

                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-primary" type="button" id="button-addon2" disabled={key === 0} title="You must provide at least one charge" onClick={() => this.removeCustomIntegrationSetting(key)}>
                                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw"/>
                                                        </button>
                                                    </div>

                                                </div>
                                            )
                                        })}

                                        <div className="btn btn-outline-primary btn-sm" onClick={() => this.addCustomIntegrationSetting()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add another setting
                                        </div>

                                    </div>

                                </div>

                            </div>
                            }

                            {this.state.integration['integrationType'] === 'RENTMANAGER' &&
                                <FieldCheckbox 
                                    id="enableCarryOverCharges" 
                                    label="CarryOver Charges" 
                                    fieldLabel="Enable CarryOver Charges"
                                    parent={this}
                                    value={this.state.enableCarryOverCharges}
                                    handleChange={this.handleChangeCarryOverCharge}/>
                            }

                            <div className="mt-3">            
                                {/* <FieldCheckbox id="pullingChargesActive" label="Auto Scheduler" fieldLabel="Schedule for automatic import of charges into Letus" model="pullingChargesScheduler" parent={this} value={this.state.pullingChargesScheduler['pullingChargesActive']} /> */}
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">
                                        Charges Scheduler
                                    </label>
                                    <div className="col-sm-9 col-form-label col-form-label-sm">
                                        Schedule for automatic import of charges into Letus.
                                    </div>
                                </div>
                                <div className='row col-form-label-sm'>
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-3">From</div>
                                    <div className="col-sm-3">To</div>
                                    <div className="col-sm-3">Time</div>
                                </div>
                                { this.state.pullingChargesSchedulerList?.map((pullingChargesSchedule, key)=>{
                                        return (
                                            <div className="mt-2" key={"scheduler"+key} >
                                                <FieldDateTimeChargesScheduler 
                                                    key={"scheduler"+key} 
                                                    idOpenDate="pullingOpenDate" 
                                                    idOpenTime="pullingOpenTime"
                                                    idCloseDate="pullingCloseDate"
                                                    label="" parent={this} 
                                                    model="pullingChargesSchedulerList" 
                                                    arrayIndex={key} 
                                                    valueOpenDate={this.state.pullingChargesSchedulerList[key]?.pullingOpenDate}
                                                    valueOpenTime={this.state.pullingChargesSchedulerList[key]?.pullingOpenTime}
                                                    valueCloseDate={this.state.pullingChargesSchedulerList[key]?.pullingCloseDate}
                                                    active={this.state.pullingChargesSchedulerList[key].active}
                                                    handleRemove={()=>this.handleRemoveSchedule(key, "OPEN_CHARGES_IMPORT")}
                                                    containerClass="mb-2"/>
                                            </div>
                                        );
                                    })
                                }
                                <div className='row col-form-label-sm form-group'>
                                    <div className="col-sm-5"></div>
                                    <div className="col-sm-7">
                                        <div className="btn btn-outline-primary btn-sm mt-2" onClick={() => {this.handleAddSchedule("OPEN_CHARGES_IMPORT")}}>
                                            <FontAwesomeIcon icon={["fas", "plus"]} className="fa-fw" />{" "}
                                            Add Schedule
                                        </div>
                                    </div>
                                </div>

                                {/* Transaction Scheduler */}
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label col-form-label-sm">
                                        Transaction Scheduler
                                    </label>
                                    <div className="col-sm-9 col-form-label col-form-label-sm">
                                        Schedule transactions to be automatically pushed to integration side.
                                    </div>
                                </div>
                                <div className='row col-form-label-sm'>
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-3">From</div>
                                    <div className="col-sm-3">To</div>
                                    <div className="col-sm-3">Time</div>
                                </div>
                                { this.state.pullingTransactionsSchedulerList?.map((pullingTransactionsSchedule, key)=>{
                                        return (
                                            <div className="mt-2" key={"scheduler"+key} >
                                                <FieldDateTimeChargesScheduler 
                                                    key={"scheduler"+key} 
                                                    idOpenDate="pullingOpenDate" 
                                                    idOpenTime="pullingOpenTime"
                                                    idCloseDate="pullingCloseDate"
                                                    label="" parent={this} 
                                                    model="pullingTransactionsSchedulerList" 
                                                    arrayIndex={key} 
                                                    valueOpenDate={pullingTransactionsSchedule.pullingOpenDate}
                                                    valueOpenTime={pullingTransactionsSchedule.pullingOpenTime}
                                                    valueCloseDate={pullingTransactionsSchedule.pullingCloseDate}
                                                    active={pullingTransactionsSchedule.active}
                                                    handleRemove={()=>this.handleRemoveSchedule(key, "TRANSACTIONS_PUSH")}
                                                    help="Above will only affect transactions with processing date of until 60 days
                                                    prior to scheduler task."
                                                    containerClass="mb-2"/>
                                            </div>
                                        );
                                    })
                                }

                                { (this.state.integration['integrationType'] === 'MRI' || 
                                   this.state.integration['integrationType'] === 'MRI_COMMERCIAL' || 
                                   this.state.integration['integrationType'] === 'YARDI' ||
                                   this.state.integration['integrationType'] === 'ENTRATA' ||
                                   this.state.integration['integrationType'] === 'RENTMANAGER' ||
                                   this.state.integration['integrationType'] === 'PROPERTYWARE' ||
                                   this.state.integration['integrationType'] === 'QUICKBOOKS' ||
                                   this.state.integration['integrationType'] === 'APPFOLIO' ||
                                   this.state.integration['integrationType'] === 'RESMAN') && 
                                    <>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Tenant Scheduler
                                            </label>
                                            <div className="col-sm-9 col-form-label col-form-label-sm">
                                                Schedule Tenant synchronization.
                                            </div>
                                        </div>
                                        <div className='row col-form-label-sm'>
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-3">From</div>
                                            <div className="col-sm-3">To</div>
                                            <div className="col-sm-3">Time</div>
                                        </div>
                                        { this.state.pullingTenantsSchedulerList?.map((pullingTenantsSchedule, key)=>{
                                                return (
                                                    <div className="mt-2" key={"scheduler"+key} >
                                                        <FieldDateTimeChargesScheduler 
                                                            key={"scheduler"+key} 
                                                            idOpenDate="pullingOpenDate" 
                                                            idOpenTime="pullingOpenTime"
                                                            idCloseDate="pullingCloseDate"
                                                            label="" parent={this} 
                                                            model="pullingTenantsSchedulerList" 
                                                            arrayIndex={key} 
                                                            valueOpenDate={this.state.pullingTenantsSchedulerList[key]?.pullingOpenDate}
                                                            valueOpenTime={this.state.pullingTenantsSchedulerList[key]?.pullingOpenTime}
                                                            valueCloseDate={this.state.pullingTenantsSchedulerList[key]?.pullingCloseDate}
                                                            active={this.state.pullingTenantsSchedulerList[key].active}
                                                            handleRemove={()=>this.handleRemoveSchedule(key, "TENANTS_IMPORT")}
                                                            containerClass="mb-2"/>
                                                    </div>
                                                );
                                            })
                                        }
                                        <div className='row col-form-label-sm form-group'>
                                            <div className="col-sm-5"></div>
                                            <div className="col-sm-7">
                                                <div className="btn btn-outline-primary btn-sm mt-2" onClick={() => {this.handleAddSchedule("TENANTS_IMPORT")}}>
                                                    <FontAwesomeIcon icon={["fas", "plus"]} className="fa-fw" />{" "}
                                                    Add Schedule
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.pullingScheduleWarning &&
                                            <div className="form-group row">
                                                <div className="col-sm-3 col-form-label col-form-label-sm">
                                                </div>
                                                <div className="col-sm-9 col-form-label col-form-label-sm">
                                                    <small className="form-text text-muted-warning">
                                                        Some of the schedules may not have been saved! 
                                                        Please make sure to select all the values, and make sure that "From" date is less than or equal to the "To" date.
                                                    </small>
                                                </div>
                                            </div>
                                        }

                                        <FieldCheckbox 
                                            id="pullLeaseDateBool" 
                                            label="Import Lease Dates" 
                                            fieldLabel="Import the Start Date and End Date for all tenants" 
                                            help="If checked, Letus will import all Tenants from integration with their respective Start Date and End Date. 
                                                If End date is before today's date, lease will be imported as terminated." 
                                            parent={this}
                                            value={this.state.pullLeaseDateBool} />
                                    </>
                                }
                                { (this.state.integration['integrationType'] === 'SAP') &&
                                <>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                            Property Scheduler
                                        </label>
                                        <div className="col-sm-9 col-form-label col-form-label-sm">
                                            Schedule Property synchronization.
                                        </div>
                                    </div>
                                    <div className='row col-form-label-sm'>
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-3">From</div>
                                        <div className="col-sm-3">To</div>
                                        <div className="col-sm-3">Time</div>
                                    </div>
                                    { this.state.pullingPropertiesSchedulerList?.map((pullingPropertiesSchedule, key)=>{
                                        return (
                                            <div className="mt-2" key={"scheduler"+key} >
                                                <FieldDateTimeChargesScheduler
                                                    key={"scheduler"+key}
                                                    idOpenDate="pullingOpenDate"
                                                    idOpenTime="pullingOpenTime"
                                                    idCloseDate="pullingCloseDate"
                                                    label="" parent={this}
                                                    model="pullingPropertiesSchedulerList"
                                                    arrayIndex={key}
                                                    valueOpenDate={this.state.pullingPropertiesSchedulerList[key]?.pullingOpenDate}
                                                    valueOpenTime={this.state.pullingPropertiesSchedulerList[key]?.pullingOpenTime}
                                                    valueCloseDate={this.state.pullingPropertiesSchedulerList[key]?.pullingCloseDate}
                                                    active={this.state.pullingPropertiesSchedulerList[key].active}
                                                    handleRemove={()=>this.handleRemoveSchedule(key, "PROPERTIES_IMPORT")}
                                                    containerClass="mb-2"/>
                                            </div>
                                        );
                                    })
                                    }
                                    <div className='row col-form-label-sm form-group'>
                                        <div className="col-sm-5"></div>
                                        <div className="col-sm-7">
                                            <div className="btn btn-outline-primary btn-sm mt-2" onClick={() => {this.handleAddSchedule("PROPERTIES_IMPORT")}}>
                                                <FontAwesomeIcon icon={["fas", "plus"]} className="fa-fw" />{" "}
                                                Add Schedule
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.pullingScheduleWarning &&
                                    <div className="form-group row">
                                        <div className="col-sm-3 col-form-label col-form-label-sm">
                                        </div>
                                        <div className="col-sm-9 col-form-label col-form-label-sm">
                                            <small className="form-text text-muted-warning">
                                                Some of the schedules may not have been saved!
                                                Please make sure to select all the values, and make sure that "From" date is less than or equal to the "To" date.
                                            </small>
                                        </div>
                                    </div>
                                    }
                                </>
                                }
                            </div>
                        </div>  
                        }

                    </div>

                    {this.state.integration != null &&
                    <div className="row">
                        <div className="col text-right">

                            {this.state.company.integrationId &&
                                <div className="btn btn-outline-danger btn-lg ml-2" data-toggle="modal" data-target="#delete-integration">
                                    Delete
                                </div>
                            }
                            {this.state.company.integrationId &&
                                this.state.integration["integrationType"] === "QUICKBOOKS" && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-lg ml-2"
                                        disabled={this.state.integration?.settings?.AUTHORIZED_EXTERNAL === 'true' ? true : false}
                                        onClick={() => this.handleQBAuthorization()}
                                    >
                                        Authorize
                                    </button>
                                )
                            }

                            <div className="btn btn-outline-primary btn-lg ml-2" onClick={() => this.testIntegration()}>
                                Test
                            </div>

                            <ButtonSave />

                        </div>
                    </div>
                    }

                </form>

                <Modal id="delete-integration" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Integration"
                       body="By deleting this integration, the integration details will be retained in the backend, but the the company will no longer be associated with the integration details.">
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.deleteIntegration()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Integration
                    </button>
                </Modal>
                <form onSubmit={this.saveIntegration}> 
                    <Modal id="save-integration-check" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Save Integration"
                        body="You are about to save “Integration Settings” with “Integrated Balance” checkbox unchecked. By clicking on “Confirm”, all scheduled charges will be disabled">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                            <FormattedMessage id="button.close" />
                        </button>
                        <ButtonSave />
                    </Modal>
                </form> 

            </React.Fragment>
        )
    };
}

Integrations.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Integrations);
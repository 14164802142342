import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import { Link, Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Services from "../../admin/tenants/Services";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Documents from "./Documents";
import Insurances from './Insurances';
import OpenCharges from "./OpenCharges";
import PaymentMethods from "./PaymentMethods";
import Tenant from "./Tenant";
import Transactions from "./Transactions";
import Events from "../../manager/tenants/Events";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            customer: {},
            propertyLease: {},

            userId: '',
            email: '',
            firstName: '',
            lastName: '',
            companyName: '',

            charges: [],

            companyId: '',
            integrationId: '',
            customChargeCodes: [],

            recurring: false,

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            integrationSearch: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                tenantId: '',
                propertyId: ''
            },

            integrationCustomerList: [],

            validationList: [],
            makeTenantPayments: '',
            accessEventHistory: false
        };

        this.getPropertyLease = this.getPropertyLease.bind(this);
        this.deletePropertyLease = this.deletePropertyLease.bind(this);
        this.getMakeTenantPaymentsFlag = this.getMakeTenantPaymentsFlag.bind(this);
    }

    /**
     * On mounting of the component, fetch the property lease data and all open charges associated with the property
     * lease at hand.
     */
    componentDidMount() {

        this.getPropertyLease();
    }

    /**
     * Get the property lease data based on the property lease ID provided in the route.
     */
    getPropertyLease() {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId']
                },
                parentCompany: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'company.parentId',
                    alias: 'parentCompany',
                    returnFields: ['name', 'chargeCodes', 'integrationId']
                },
                customer: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'customer',
                    returnFields: ['firstName', 'lastName', 'email']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(!response.data.records[0]) {
                this.handleValidation({error: 'error'});
                return
            }
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLease: response.data.records[0],
                userId: response.data.records[0].userId,
                email: response.data.records[0].joins.customer.email,
                firstName: response.data.records[0].joins.customer.firstName,
                lastName: response.data.records[0].joins.customer.lastName,
                companyName: response.data.records[0].companyName,
            }), () => this.getMakeTenantPaymentsFlag());

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all available payment methods for a company
     */
    searchCompanyPaymentMethods() {
        return axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.propertyLease?.companyId}/peek`, {
            headers: this.generateRequestHeaders()
        });
    }

     /**
     * Search for all available payment methods for a property
     */
    searchPropertyPaymentMethods() {
        return axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.state.propertyLease?.propertyId}/peek`, {
            headers: this.generateRequestHeaders()
        });
    }

    /**
     * Get the flag for make tenants payments.
     */
     getMakeTenantPaymentsFlag() {

        axios
        .get(
          `${constants.REACT_APP_HOST_API_URL}/manager/${this.props.userSession.sessionRole.id}`,
          {
            headers: this.generateRequestHeaders(),
          }
        )
        .then((makeTenantPaymentsResponse) => {

            this.setState((prevState) => ({
                ...prevState,
                accessEventHistory:
                    makeTenantPaymentsResponse?.data?.accessEventHistory,
            }));

            this.searchCompanyPaymentMethods().then(responseCompany => {
                        
            const isCashPaymentAllowedCompany = responseCompany.data.acceptedPaymentTypes.includes('TYPE_CASH');

            this.searchPropertyPaymentMethods().then(responseProperty => {
                
                const isCashPaymentAllowedProperty = responseProperty.data.acceptedPaymentTypes.includes('TYPE_CASH');
                const propertyCountry = responseProperty.data.country

                this.setState((prevState) => ({
                  ...prevState,
                  makeTenantPayments:
                    (!this.state.propertyLease?.blockPayments?.TYPE_CASH?.blocked &&
                      (propertyCountry === 'US' && (isCashPaymentAllowedProperty || isCashPaymentAllowedCompany))) ||
                    makeTenantPaymentsResponse.data.makeTenantPayments,
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

            }).catch(error => {
                this.handleValidation(error);
            });
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    }

    /**
     * Delete the property lease currently being viewed.
     */
    deletePropertyLease() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: `/manager/tenants`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'manager.tenants.deleted'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Redirect the user to the relevant property view component.
     */
    viewProperty() {

        this.props.history.push({
            pathname: `/manager/properties/${this.state.propertyLease.propertyId}/edit`,
            state: {
                property: {
                    id: this.state.propertyLease.propertyId
                }
            }
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });
        
        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/manager/tenants" parentPage="Tenants" childPage={(this.state.companyName ? this.state.companyName + ' ' : '') + this.state.firstName + ' ' + this.state.lastName} />

                    {this.props.location.state &&
                    <Alert validationList={this.props.location.state.validationList} />
                    }

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/manager/tenants/${this.props.match.params.propertyLeaseId}/edit`} active="edit" size="small" iconName="user" name="Tenant" />
                                        <NavListItem path={`/manager/tenants/${this.props.match.params.propertyLeaseId}/statement`} active="statement" size="small" iconName="usd-circle" name="Billing" />
                                        <NavListItem path={`/manager/tenants/${this.state.propertyLease.id}/services`} active="services" size="small" iconName="money-check-alt" name="Services" />
                                        <NavListItem path={`/manager/tenants/${this.props.match.params.propertyLeaseId}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />
                                        <NavListItem path={`/manager/tenants/${this.props.match.params.propertyLeaseId}/methods`} active="methods" size="small" iconName="wallet" name="Payment Methods" />
                                        <NavListItem path={`/manager/tenants/${this.props.match.params.propertyLeaseId}/documents`} active="documents" size="small" iconName="file-alt" name="Documents" />
                                        <Link to={`/manager/tenants/${this.props.match.params.propertyLeaseId}/events`} className={`list-group-item list-group-item-action c-pointer ${this.state.accessEventHistory? '' : 'disabled'} `}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'history']} className="fa-fw va-b mr-2" />
                                                    Event History
                                                </div>
                                            </div>
                                        </Link>
                                        <NavListItem path={`/manager/tenants/${this.state.propertyLease.id}/insurances`} active="insurances" size="small" iconName="user-shield" name="Insurance Details" />
                                        <Link to={`/manager/tenants/${this.props.match.params.propertyLeaseId}/payment`} className={`list-group-item list-group-item-action c-pointer ${this.state.makeTenantPayments && !(this.props.userSession?.sessionRole?.roles?.length === 1 && this.props.userSession?.sessionRole?.roles?.includes('LEASING_MANAGER'))? '' : 'disabled'} `}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'usd-square']} className="fa-fw va-b mr-2" />
                                                    Make a Payment
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            </div>

                            {this.state.propertyLease.joins &&
                            <div className="jumbotron border py-4">
                                <div className="container">

                                    <p className="mb-0">
                                        {this.state.firstName} {this.state.lastName} resides at <span className="btn-link c-pointer font-weight-bold" onClick={() => this.viewProperty()}>{this.state.propertyLease.joins?.property?.propertyName ? `${this.state.propertyLease.joins?.property?.propertyName} - `:''}{this.state.propertyLease.joins.property.street1}</span>.
                                    </p>

                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/edit" type="TYPE_MANAGER" parent={this} component={Tenant} refreshPropertyLease={(propertyLeaseId) => this.getPropertyLease(propertyLeaseId)} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/methods" type="TYPE_MANAGER" parent={this} component={PaymentMethods} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/services" type="TYPE_MANAGER" parent={this} component={Services} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/statement" type="TYPE_MANAGER" parent={this} component={OpenCharges} userSession={this.props.userSession}/>
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/transactions" type="TYPE_MANAGER" parent={this} component={Transactions} userId={this.state.userId} userSession={this.props.userSession}/>
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/documents" type="TYPE_MANAGER" parent={this} component={Documents} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/events" type="TYPE_MANAGER" component={Events} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/insurances" type="TYPE_MANAGER" component={Insurances}/>
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);
